<div class="pax-selector">
    <div class="lay" *ngFor="let pax of paxList; let index = index;">
        <div class="pax-selector--labels">
            <span class="title title--dark">{{pax.paxName}}</span>
        </div>
        <div class="number-selector">
            <button
                [ngClass]="{'disabled' : pax.paxQuantity <= 0}"
                (click)="remove(index)" 
                class="button button--circle button--primary-outline">-</button>
            <span>{{pax.paxQuantity}}</span>
            <button
                [ngClass]="{'disabled' : paxAddonQuantity == availablePax }"
                (click)="add(index)"  
                class="button button--circle button--primary-outline">+</button>
        </div>
    </div>
</div>
<div class="pax-save-actions">
    <button (click)="onCloseAddon()" class="button button--primary-outline">{{"BUTTON.CANCEL" | translate}}</button>
    <button  (click)="onSaveAddon()" class="button button--primary">{{"BUTTON.ADD" | translate}}</button>
</div>