import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { AppService } from '../services/app/app.service';

export const PreventInitializationGuard: CanActivateFn = async (route, state) => {
  try {
    const token = route.queryParams['tk'];

    if(!token){
      throw false;
    }
  
    const authService = inject(AuthService);
    const appService = inject(AppService);
    const isInitialized = appService.checkInitializeApp();
    if(isInitialized){
      return true;
    }  

    authService.setToken(token);
    await appService.setConfig(token);
    return true;
  } catch (error) {
    console.log(error);
    throw false;
  }  
};