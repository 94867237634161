import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPax } from 'src/app/Interfaces/Models/Pax.interface';
import { environment } from 'src/environment/environment';

@Component({
  selector: 'app-select-pax',
  templateUrl: './select-pax.component.html',
  styleUrls: ['./select-pax.component.css']
})
export class SelectPaxComponent {
  @Input()  paxList : IPax[] = [];
  @Input()  paxAddonQuantity : number = 0;
  @Output() onAdd : EventEmitter<number> = new EventEmitter();
  @Output() onSave : EventEmitter<IPax[]> = new EventEmitter();
  @Output() onClose : EventEmitter<boolean> = new EventEmitter();
  
  public availablePax : number = environment.MAX_PAX;

  onSaveAddon(){
    this.onSave.emit(this.paxList);
  }

  onCloseAddon(){
    this.onClose.emit(true);
  }

  add(paxIndex : number){
    this.paxList[paxIndex].paxQuantity ++;
    this.onAdd.emit(this.paxList[paxIndex].paxQuantity)
  }

  remove(paxIndex : number){
    this.paxList[paxIndex].paxQuantity --;
    this.onAdd.emit(this.paxList[paxIndex].paxQuantity)
  }
}
