import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @Input('logoUrl') logoUrl! : string;
  @Input('href') href!: string;

  @Output('closeEvent') closeEvent : EventEmitter<boolean> = new EventEmitter;

  public showCloseModal : boolean = false;

  goToHome(){
    const dominio = new URL(this.href).origin;
    window.open(dominio);
  }

  close(){
    this.closeEvent.emit(true);
  }
}
