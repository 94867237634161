import { Component } from '@angular/core';

@Component({
  selector: 'app-tag-action',
  templateUrl: './tag-action.component.html',
  styleUrls: ['./tag-action.component.css']
})
export class TagActionComponent {

}
