import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MitPaymentComponent } from './components/payment/mit-payment/mit-payment.component';
import { ReservationConfirmationComponent } from './components/payment/reservation-confirmation/reservation-confirmation.component';
import { PreventInitializationGuard } from './guards/prevent-initialization.guard';
import { PersonalInformationViewComponent } from './views/personal-information/personal-information.view.component';
import { AddonViewComponent } from './views/addon/addon.view.component';
import { PaymentViewComponent } from './views/payment/payment.view.component';
import { ResumeViewComponent } from './views/resume/resume.view.component';
import { BookingComponent } from './views/booking/booking.component';
import { SupportComponent } from './views/support/support.component';
import { OfflineComponent } from './views/offline/offline.component';
import { SupportReservationComponent } from './views/support-reservation/support-reservation.component';
import { ConfirmationComponent } from './views/confirmation/confirmation.component';
import { PaymentLinkViewComponent } from './views/payment-link/payment-link.view.component';
import { CalendarPriceComponent } from './components/calendar-price/calendar-price.component';

const routes: Routes = [
  {
    path:"booking", 
    data : {en : 'Booking', es : 'Reserva'},
    component: BookingComponent,
    canActivate : [PreventInitializationGuard]
  },
  {
    path:"resume", 
    component: ResumeViewComponent,
    canActivate : [PreventInitializationGuard]
  },
  {
    path:"personal-information",
    data : {en : 'Contact', es : 'Contacto'},
    component : PersonalInformationViewComponent,
    canActivate : [PreventInitializationGuard]
  },
  {
    path:"addons",
    data :{en : 'Addons', es : 'Adicionales'},
    component : AddonViewComponent,
    canActivate : [PreventInitializationGuard]
  },
  {
    path:"payment",
    data : {en : 'Payment', es : 'Pago'},
    component : PaymentViewComponent,
    canActivate : [PreventInitializationGuard]
  },
  {
    path:"payment/:reservationId",                                  
    component : PaymentLinkViewComponent,
    data : {en : 'Payment', es : 'Pago'},
  },
  {
    path : 'reservation-confirmation/:reservationId', 
    component : ConfirmationComponent,
  },
  {
    path:"mit-payment", 
    component: MitPaymentComponent
  },
  {
    path: "support",
    component : SupportComponent
  },
  {
    path: "support-reservation",
    component : SupportReservationComponent
  },
  {
    path : "offline",
    component : OfflineComponent
  },
  /*{
    path:"calendar-price",                                  
    component : CalendarPriceComponent,
    canActivate : [PreventInitializationGuard]
  }*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
