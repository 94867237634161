import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { StepEnum } from 'src/app/models/enums/Step.enum';
import { AppService } from 'src/app/services/app/app.service';
import { ClientService } from 'src/app/services/client/client.service';
import { RouterService } from 'src/app/services/router/router.service';
import { environment } from 'src/environment/environment';

interface IWizardByLocation {
  [key: string]: string[];
}

interface IWizard {
  [key: number]: IWizardByLocation;
}

@Component({
  selector: 'app-personal-information.view',
  templateUrl: './personal-information.view.component.html',
  styleUrls: ['./personal-information.view.component.css']
})
export class PersonalInformationViewComponent {
  public clientFormValid : boolean = false;
  public termsAndConditionsValid : boolean = false;
  public privatePolicyUrl$ = this.appService.privatePolicyUrl$;
  public termsConditionsUrl$ = this.appService.termsConditionsUrl$;

  constructor(
    private routerService : RouterService,
    private clientService : ClientService,
    private appService : AppService
  ) {}

  ngOnInit() {
    this.routerService.setStep(StepEnum.USER_FORM);

    if(!this.clientService.getAcceptPrivacy() || !this.clientService.getAcceptTerms()){
      this.routerService.setBlock(true);
    } else {
      this.termsAndConditionsValid = true;
      this.routerService.setBlock(false);
    }
  }

  onValidClientForm(valid : boolean) {
    this.clientFormValid = valid;
    if (this.clientFormValid && this.termsAndConditionsValid) {
      this.routerService.setBlock(false);
    } else this.routerService.setBlock(true);
  }

  onValidTermsAndConditions(valid : boolean) {
    this.termsAndConditionsValid = valid;
    if (this.clientFormValid && this.termsAndConditionsValid)
      this.routerService.setBlock(false);
    else
      this.routerService.setBlock(true);
  }

  public goToNext(){
    this.routerService.goToNext();
  }
}
