import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IClient } from 'src/app/models/Client.model';

import { KlaviyoService } from '../klaviyo/klaviyo.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private client : IClient = {
    name : "",
    lastName : "",
    email :"",
    phone : "",
    address : "N/A",
    country : "",
    state : "N/A",
    comments : "",
  }

  private acceptTerms : boolean = false;
  private acceptTermsSubject : BehaviorSubject<boolean> = new BehaviorSubject(this.acceptTerms);
  public acceptTerms$ : Observable<boolean> = this.acceptTermsSubject.asObservable();

  private acceptPrivacy : boolean = false;
  private acceptPrivacySubject : BehaviorSubject<boolean> = new BehaviorSubject(this.acceptPrivacy);
  public acceptPrivacy$ : Observable<boolean> = this.acceptPrivacySubject.asObservable();

  private clientSubject : BehaviorSubject<IClient> = new BehaviorSubject(this.client);
  public client$ : Observable<IClient> = this.clientSubject.asObservable();

  constructor(
    private klaviyoService : KlaviyoService,
  ){

  }

  addClient(client : IClient){
    this.client = client;
    this.acceptTermsSubject.next(true);
    this.clientSubject.next(this.client);

    // this.klaviyoService.Event_Identify();
    // name    : this.billingForm.value.name,
    // lastName: this.billingForm.value.lastName,
    // email   :this.billingForm.value.email,
    // phone   : this.billingForm.value.phone,
    // address : this.billingForm.value.address,
    // comments : this.billingForm.value.comments,
    // country : this.billingForm.value.country.countryName,
    // state : this.billingForm.value.state.stateName ?? 'N/A'
  }

  getClient(){
    return this.clientSubject.getValue();
  }

  setAcceptTerms(flag :boolean){
    return this.acceptTermsSubject.next(flag);
  }

  setAcceptPrivacy(flag : boolean){
    return this.acceptPrivacySubject.next(flag);
  }


  getAcceptTerms(){
    return this.acceptTermsSubject.value;
  }

  getAcceptPrivacy(){
    return this.acceptPrivacySubject.value;
  }
}
