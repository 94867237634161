import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-mit',
  templateUrl: './mit.component.html',
  styleUrls: ['./mit.component.css']
})
export class MitComponent {
  @Input('url') url! : string;
  public secureUrl! : SafeUrl;
  public showMit : boolean = false; 
  constructor(
    private sanitizer: DomSanitizer
  ) {}

  ngOnChanges(){
    if(this.url != undefined){
      this.secureUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      this.showMit= true;
    }
  }
}
