import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor(
    private activeRoute : ActivatedRoute,
    private router : Router  
  ) { }

  
  public goToCheckout()
  {
    const token = this.activeRoute.snapshot.queryParamMap.get('tk') as string;
    this.router.navigateByUrl(`/checkout?tk=${token}`)
  }

  public goToHome(){
    const token = this.activeRoute.snapshot.queryParamMap.get('tk') as string;
    this.router.navigateByUrl(`/home?tk=${token}`)
  }
}
