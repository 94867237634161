import { Injectable, Signal, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import { environment } from 'src/environment/environment';
import { IGeneralResponseDTO } from 'src/app/models/responses/GeneralResponse.dto';
import { IGetZonesResponseDTO } from 'src/app/models/responses/GetZonesResponse.dto';
import { lastValueFrom, map } from 'rxjs';
import { IZone } from 'src/app/models/Zone.model';

@Injectable({
  providedIn: 'root'
})
export class ZonesService {

  private zonesSignal = signal<IZone[]>([]);

  public zones = this.zonesSignal.asReadonly();

  private getZonesUrl: string = `${environment.MAIN_URL}${environment.ZONES.GET_ZONES}`


  constructor(private http: HttpClient) { }

  public async getZones(): Promise<void> {
    const observable = this.http.get<IGeneralResponseDTO<IGetZonesResponseDTO>>(this.getZonesUrl).pipe(
      map((response) => this.zonesTransformer(response))
    );

    let response = await lastValueFrom(observable);
    this.zonesSignal.update(zones => [...response]);
  }

  public zonesTransformer(zoneList: IGeneralResponseDTO<IGetZonesResponseDTO>, selected: boolean = false): IZone[] {
    let zones = zoneList.data as IGetZonesResponseDTO[];
    return zones.map((zone) => {
      return {
        id: zone.zoneId,
        name: zone.zoneName,
        selected: selected
      }
    });
  }
}
