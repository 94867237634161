import { Injectable, Signal, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import { environment } from 'src/environment/environment';
import { IGeneralResponseDTO } from 'src/app/models/responses/GeneralResponse.dto';
import { IGetZonesResponseDTO } from 'src/app/models/responses/GetZonesResponse.dto';
import { lastValueFrom, map } from 'rxjs';
import { IHotel } from 'src/app/models/Hotel.model';
import { IGetHotelsResponseDTO } from 'src/app/models/responses/GetHotelsResponse.dto';

@Injectable({
  providedIn: 'root'
})
export class HotelService {

  private hotelsSignal = signal<IHotel[]>([]);

  public hotels = this.hotelsSignal.asReadonly();

  private getHotelsUrl: string = `${environment.MAIN_URL}${environment.HOTELS.GET_HOTELS}`

  constructor(private http: HttpClient) { }

  public async getHotels(zoneName: string | null = null): Promise<void> {
    let endPoint = zoneName
      ? `${this.getHotelsUrl}?zoneId=${zoneName}`
      : `${this.getHotelsUrl}?pageSize=500`;
    const observable = this.http
      .get<IGeneralResponseDTO<IGetHotelsResponseDTO>>(endPoint)
      .pipe(map((response) => this.hotelsTransformer(response)));

    let response = await lastValueFrom(observable);
    this.hotelsSignal.update(hotels => [...response]);
  }

  public hotelsTransformer(zoneList: IGeneralResponseDTO<IGetHotelsResponseDTO>): IHotel[] {
    let hotels = zoneList.data as IGetHotelsResponseDTO[];
    return hotels.map((hotel) => {
      return {
        id: hotel.hotelId,
        name: hotel.hotelName,
        zone: hotel.hotelZone
      }
    });
  }
}
