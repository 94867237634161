<ng-container *ngIf="shopcart$ | async as shopcart">
  <!-- <small>step$: {{ (step$ | async) }}</small> -->
  <ng-container *ngIf="step$ | async as step">
    <div class="resume-component">
      <div class="card-resume">
        <div class="card-resume--content">
          <span class="title title--dark">{{ "RESUME.TITLE_1" | translate}}</span>
          <span class="subtitle subtitle--light">
            <span
              *ngIf="shopcart.productList.length == 1 || shopcart.addonList.length == 1">{{(shopcart.productList.length
              + shopcart.addonList.length )}} {{ "RESUME.ACTIVITY" | translate}}</span>
            <span
              *ngIf="shopcart.productList.length > 1 && shopcart.addonList.length > 1">{{(shopcart.productList.length +
              shopcart.addonList.length )}} {{ "RESUME.ACTIVITIES" | translate}}</span>
          </span>
        </div>
        <div class="card-resume--interaction">
          <button *ngIf="shopcart.productList.length > 0 " (click)="onClickCollapse()" class="button-icon">
            <mat-icon [ngClass]="{'clicked-arrow' : showCollapse }" aria-hidden="false"
              fontIcon="expand_more"></mat-icon>
          </button>
        </div>
      </div>

      <div class="card-collapse" [ngClass]="{'hidden' : !showCollapse }">
        <!-- <small><code [innerHTML]="shopcart.productList | json"></code></small> -->
        <ng-container *ngFor="let product of shopcart.productList">
          <div class="card-product">
            <div class="image-box">
              <img class="image-box--product" [src]="product.image ? product.image : 'assets/product-placeholder.png'" width="38" height="38">
            </div>
            <div class="card-product--content">
              <span class="product-title">{{product.name}}</span>
              <!-- <span class="subtitle subtitle--light">proporcionado por: Selvatica</span> -->
            </div>
          </div>
          <div class="resume">
            <div class="resume--content">
              <div class="resume--label">
                <mat-icon aria-hidden="false" fontIcon="person_outline"></mat-icon>
                <span class="product--detail-text">
                  <ng-container *ngFor="let pax of product.paxList; let index">
                    {{ pax.quantity }} {{pax.name}}
                  </ng-container>
                </span>
              </div>
              <div class="resume--label">
                <mat-icon aria-hidden="false" fontIcon="date_range"></mat-icon>
                <span class="product--detail-text">{{ product.schedule?.date| date:'dd/MM/yyyy HH:mm aaa' }}</span>
              </div>
              <div *ngIf="showPromocodeByProduct(product)" class="resume--label" style="width: fit-content; background-color: #d8ead2; color: #2a6c16; border-radius: 8px; padding: 4.5px 11px; justify-content: flex-start;">
                <mat-icon aria-hidden="false" fontIcon="sell" style="font-size: 16px; width: 16px; height: 16px;;"></mat-icon>
                <span class="product--detail-text">{{ shopcart.discountCode }}</span>
                <button *ngIf="[stepEnum.BOOKING, stepEnum.USER_FORM, stepEnum.ADDON].includes(step)" (click)="clearDiscount()" style="cursor: pointer; flex: auto; display: flex; justify-content: end; background: none; border: none; width: 35px; color: inherit;"><mat-icon>close</mat-icon></button>
              </div>
            </div>

            <div class="resume--total">
              <span *ngIf="product.subtotal > product.total" class="product--price strikethrough">
                {{product.subtotal | currency}}
                <ng-container *ngIf="currency$ | async as currency">
                  {{currency.symbol}}
                </ng-container>
              </span>
              <span class="product--price">
                {{product.total | currency}}
                <ng-container *ngIf="currency$ | async as currency">
                  {{currency.symbol}}
                </ng-container>
              </span>
            </div>
          </div>
        </ng-container>

        <!-- <small><code [innerHTML]="shopcart.addonList | json"></code></small> -->
        <ng-container *ngFor="let addon of shopcart.addonList">
          <div class="card-product">
            <div class="image-box">
              <img class="image-box--product" [src]="addon.addonImage" alt="" width="38" height="38">
            </div>
            <div class="card-product--content">
              <span class="product-title">{{addon.addonName}}</span>
              <!-- <span class="subtitle subtitle--light">proporcionado por: Selvatica</span> -->
            </div>
            <div class="action">
              <mat-icon (click)="onDeleteAddon(addon)" class="icon icon--primary" aria-hidden="false"
                fontIcon="delete_outline"></mat-icon>
            </div>
          </div>
          <div class="resume">
            <div class="resume--content">
              <div class="resume--label">
                <mat-icon class="icon" aria-hidden="false" fontIcon="person_outline"></mat-icon>
                <span class="product--detail-text">
                  <ng-container *ngFor="let pax of addon.paxList; let index">
                    {{ pax.paxQuantity }} {{pax.paxName}}
                  </ng-container>
                </span>
              </div>
              <div class="resume--label"
                *ngIf="addon.addonId == transportationIdADO">
                  <mat-icon aria-hidden="false" fontIcon="date_range"></mat-icon>
                  <!-- <span>{{ product.checkInDate | date:'dd/MM/yyyy' }}</span> -->
                  <!-- <span>{{ product.checkInTime | date:'HH:mm aaa'}}</span> -->
                  <span class="product--detail-text">9:00 AM</span>
              </div>
            </div>
            <div class="resume--total">
              <span class="product--price">
                {{addon.total | currency}}
                <ng-container *ngIf="currency$ | async as currency">
                  {{currency.symbol}}
                </ng-container>
              </span>
            </div>
          </div>
        </ng-container>

        <!-- <small><code [innerHTML]="shopcart.transportation | json"></code></small> -->
        <ng-container *ngIf="shopcart.transportation">
          <div class="card-product">
            <div class="image-box">
              <img class="image-box--product"
                src="https://cdn1.dtraveller.net/content/venturapark/widget/selvatica/addon-transportation.jpg"
                width="38" height="38">
            </div>
            <div class="card-product--content">
              <span class="product-title">{{shopcart.transportation.hotelName}}</span>
              <!-- <small>{{shopcart.transportation.name}}</small> -->
            </div>
            <div class="action">
              <mat-icon (click)="onDeleteTransportation(null)" class="icon icon--primary" style="cursor: pointer;" aria-hidden="false"
                fontIcon="delete_outline"></mat-icon>
            </div>
          </div>
          <div class="resume">
            <div class="resume--content">
              <div class="resume--label">
                <mat-icon aria-hidden="false" fontIcon="person_outline"></mat-icon>
                <span class="product--detail-text">
                  <ng-container *ngFor="let pax of shopcart.transportation.paxList; let index">
                    {{ pax.quantity }} {{pax.name}}
                  </ng-container>
                </span>
              </div>
              <div class="resume--label">
                <mat-icon aria-hidden="false" fontIcon="schedule"></mat-icon>
                <span class="product--detail-text">{{ 'TRANSPORTATION.PICKUP' | translate }} {{ shopcart.transportation.pickUpFrontEnd | date:'HH:mm aaa' }}</span>
              </div>
            </div>

            <div class="resume--total">
              <span *ngIf="shopcart.transportation.subtotal > shopcart.transportation.total"
                class="product--price strikethrough">
                {{shopcart.transportation.subtotal | currency}}
                <ng-container *ngIf="currency$ | async as currency">
                  {{currency.symbol}}
                </ng-container>
              </span>
              <span class="product--price">
                {{shopcart.transportation.total | currency}}
                <ng-container *ngIf="currency$ | async as currency">
                  {{currency.symbol}}
                </ng-container>
              </span>
            </div>
          </div>
        </ng-container>
      </div>

      <form action="" [formGroup]="discountForm" *ngIf="showDiscount">
        <section class="card-discount">
          <ng-container *ngIf="!showCollapseDiscount && [stepEnum.BOOKING].includes(step)">
            <span class="card-discount--title" (click)="showDiscountInput()"
              [ngClass]="{'disabled' : shopcart.productList.length === 0}">
              <mat-icon class="icon" aria-hidden="false" fontIcon="redeem"></mat-icon>
              <span class="subtitle">{{"BOOKING.APPLY_DISCOUNT" | translate}}</span>
            </span>
          </ng-container>

          <ng-container *ngIf="shopcart.productList.length > 0 && showCollapseDiscount">
            <div class="card-discount--collapse">
              <span class="subtitle">
                {{"BOOKING.PLACEHOLDER_DISCOUNT" | translate}}
              </span>
              <div class="card-discount--form">
                <div class="card-discount--input">
                  <input matInput formControlName="discount" (keypress.enter)="onApplyDiscount($event)"
                    (keypress)="cleanSpecialCharacters($event)" (blur)="onBlurDiscount($event)" >
                  <button (click)="clearDiscountForm($event)" class="button-icon" *ngIf="discountForm.value.discount">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
                <button (click)="onApplyDiscount($event)" [ngClass]="{'disabled' : !discountFormValid}"
                  class="card-discount--button">
                  <span>{{'BUTTON.REDEEM' | translate}}</span>
                </button>
              </div>
              <div class="disclaimer-layout margin-border">
                <span *ngIf="showDiscountError" class="text--error">{{'BOOKING.ERROR_DISCOUNT' | translate}}</span>
                <span *ngIf="showDiscountErrorDaysInAdvance" class="text--error">{{'BOOKING.ERROR_DISCOUNT_DAYS_IN_ADVANCE' | translate: { DAYS_NUMBER: daysInAdvance } }}</span>
                <span *ngIf="showDiscountErrorNotApplicable" class="text--error">{{'BOOKING.ERROR_DISCOUNT_NOT_APPLICABLE' | translate}}</span>
                <span *ngIf="showDiscountErrorDayOnTheWeek" class="text--error">{{'BOOKING.ERROR_DISCOUNT_ERROR_DAY_ON_THE_WEEK' | translate}}</span>
                <mat-chip-set aria-label="Week Inventory" *ngIf="showDiscountErrorDayOnTheWeek" style="--mdc-chip-label-text-size: .7rem">
                  <mat-chip *ngFor="let day of ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']; index as i" [disabled]="isDayOfTheWeekDisabled(day)">
                    {{ getDayName(i) | titlecase }}
                  </mat-chip>
                </mat-chip-set>
              </div>
            </div>
          </ng-container>

          <div *ngIf="bogo > 0" class="disclaimer-layout margin-border">
            <span class="title text--success">{{'BOOKING.BOGO_DISCOUNT' | translate}} {{bogo}}
              {{'BOOKING.BOGO_DISCOUNT_2' | translate}}</span>
          </div>
        </section>
      </form>

      <div *ngIf="shopcart.shopcartSubtotal > shopcart.shopcartTotal" class="card-total">
        <div class="card-total--resume">
          <span class="price--title">{{ "RESUME.SUBTOTAL_PRICE" | translate}}</span>
        </div>
        <div class="card-total--total">
          <span class="price--total">
            <span class="strikethrough">
              {{ shopcart.shopcartSubtotal | currency}}
            </span>
            <ng-container *ngIf="currency$ | async as currency">
              {{currency.symbol}}
            </ng-container>
          </span>
        </div>
      </div>

      <div class="card-total">
        <div class="card-total--resume">
          <span class="price--title">{{ "RESUME.TOTAL_PRICE" | translate}}</span>
          <span class="price--subtitle">{{"RESUME.DISCLAIMER" | translate}}</span>
        </div>
        <div class="card-total--total">
          <span class="price--total">
            {{ shopcart.shopcartTotal | currency}}
            <ng-container *ngIf="currency$ | async as currency">
              {{currency.symbol}}
            </ng-container>
          </span>
        </div>
      </div>
    </div>

    <button (click)="nextPage()" *ngIf="step !== stepEnum.PAYMENT" [ngClass]="{'disabled' : stepBlock$ | async }"
      class="button button--primary desktop">
      <span>{{ 'BUTTON.CONTINUE' | translate }}</span>
    </button>
    <!-- <small>stepBlock: {{stepBlock$ | async}}</small> -->
    <!-- <small>bookingFull: {{ bookingFull }}</small> -->
  </ng-container>

  <!-- <small>productList: {{shopcart.productList.length > 0}}</small> -->
  <!-- <small>showDiscount: {{showDiscount}}</small> -->
  <!-- <small>showCollapseDiscount: {{showCollapseDiscount}}</small> -->

  <!-- <small>shopcart:<pre [innerHTML]="shopcart | json"></pre></small> -->
</ng-container>
