import { Injectable } from '@angular/core';
import { IConfig } from 'src/app/Interfaces/States/Config';
import { HttpService } from '../http/http.service';
import {TranslateService} from '@ngx-translate/core';
import { environment } from 'src/environment/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import { CurrencyService } from '../currency/currency.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private initialized: boolean = false;

  private app: IConfig = {
    sellerId: 0,
    locationId: 0,
    locationName: '',
    currencyId: 0,
    currencyCode: '',
    lang: 'es',
    logoUrl: '',
    privacyPolicyUrl: '',
    termsConditionsUrl: '',
    paypalClientId: environment.PAYPAL_MODE.includes('sandbox') ? environment.PAYPAL_SANDBOX_CLIENT_ID : environment.PAYPAL_LIVE_CLIENT_ID,
  };

  private currencyInitialState: string = 'MXN';
  private currencySubject: BehaviorSubject<string> = new BehaviorSubject(
    this.currencyInitialState
  );
  public readonly currency$: Observable<string> =
    this.currencySubject.asObservable();

  private locationNameSubject: Subject<string> = new Subject();
  public readonly locationName$: Observable<string> =
    this.locationNameSubject.asObservable();

  private logoSubject: Subject<string> = new Subject();
  public readonly logo$: Observable<string> = this.logoSubject.asObservable();

  private privatePolicyUrlSubject: BehaviorSubject<string> =
    new BehaviorSubject('');
  public readonly privatePolicyUrl$: Observable<string> =
    this.privatePolicyUrlSubject.asObservable();

  private termsConditionsUrlSubject: BehaviorSubject<string> =
    new BehaviorSubject('');
  public readonly termsConditionsUrl$: Observable<string> =
    this.termsConditionsUrlSubject.asObservable();

  constructor(
    private httpService: HttpService,
    private translateService: TranslateService,
    private currencyService: CurrencyService
  ) {}

  public setLocation(locationId: number, locationName: string) {
    this.app.locationId = locationId;
    this.app.locationName = locationName;
    this.locationNameSubject.next(locationName);
  }

  public setLang(lang: string) {
    registerLocaleData(lang);
    this.translateService.use(lang);
  }

  public setCurrency(currencyCode: string, currencyId: number) {
    this.app.currencyCode = currencyCode;
    this.app.currencyId = currencyId;
    this.currencySubject.next(currencyCode);
  }

  public setLogoUrl(logoUrl: string) {
    this.app.logoUrl = logoUrl;
    this.logoSubject.next(logoUrl);
  }

  public setTerms(link: string) {
    this.termsConditionsUrlSubject.next(link);
  }

  public setPolicy(link: string) {
    this.privatePolicyUrlSubject.next(link);
  }

  public getAppConfig() {
    return this.app;
  }

  public getLocation() {
    return {
      locationId: this.app.locationId,
      locationName: this.app.locationName,
    };
  }

  public getSeller() {
    return { sellerId: this.app.sellerId };
  }

  public getCurrentSeller() {
    return this.app.sellerId;
  }

  public async setConfig(token: string) {
    const { data, length } = await this.httpService.getAppData(token);

    this.app = {
      sellerId: data.sellerId,
      locationId: data.locationId,
      locationName: data.locationName,
      currencyId: data.currencyId,
      currencyCode: data.currencyCode,
      lang: data.lang,
      logoUrl: data.logoUrl,
      privacyPolicyUrl: data.privacyPolicyUrl,
      termsConditionsUrl: data.termsConditionsUrl,
      paypalClientId: environment.PAYPAL_MODE.includes('sandbox') ? environment.PAYPAL_SANDBOX_CLIENT_ID : environment.PAYPAL_LIVE_CLIENT_ID,
    };

    /* notify the currency code to child components */
    this.currencySubject.next(data.currencyCode);
    this.locationNameSubject.next(data.locationName);
    this.logoSubject.next(data.logoUrl);
    this.termsConditionsUrlSubject.next(data.termsConditionsUrl);
    this.privatePolicyUrlSubject.next(data.privacyPolicyUrl);
    this.currencyService.setCurrency({
      id: data.currencyId,
      symbol: data.currencyCode,
    });
    this.setLang(data.lang);

    return true;
  }

  public async getConfig() {
    const url = new URL(window.location.href);
    const token = url.searchParams.get('tk') as string;
    const { data, length } = await this.httpService.getAppData(token);

    this.app = {
      sellerId: data.sellerId,
      locationId: data.locationId,
      locationName: data.locationName,
      currencyId: data.currencyId,
      currencyCode: data.currencyCode,
      lang: data.lang,
      logoUrl: data.logoUrl,
      privacyPolicyUrl: data.privacyPolicyUrl,
      termsConditionsUrl: data.termsConditionsUrl,
      paypalClientId: environment.PAYPAL_SANDBOX_CLIENT_ID,
    };

    /* Set the lang or idiom to app at initialize */
    this.setLang(data.lang);

    return true;
  }
  /* currency */

  public getCurrency() {
    return {
      currencyId: this.app.currencyId,
      currencyCode: this.app.currencyCode,
    };
  }

  public getCurrentCurrency() {
    return this.app.currencyCode;
  }

  /* Translate methods */

  public getCurrentLang(): string {
    return this.app.lang;
  }

  public checkInitializeApp() {
    return this.initialized;
  }
}
