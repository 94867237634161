<div class="card-pax">
    <div class="card-pax--header">
        <mat-icon (click)="onCloseDialog()"
            fontIcon="close">
        </mat-icon>        
        <span class="title title--dark">{{"PAX_MODAL.TITLE" | translate}}</span>
    </div>
    
    <ng-container *ngFor="let pax of paxListReference; let index = index;">
        <div class="card-pax--content">
            <div class="label-box">
                <span class="title title--dark">{{pax.paxName}}</span>
            </div>
            <div class="number-selector">
                <button
                    [ngClass]="{'disabled' : pax.paxQuantity <= 0}"
                    (click)="remove(index)" 
                    class="button button--circle button--primary-outline">-</button>
                <span>{{pax.paxQuantity}}</span>
                <button
                    (click)="add(index)"  
                    class="button button--circle button--primary-outline">+</button>
            </div>
        </div>
    </ng-container>
    <div class="button-section">
        <button
            (click)="onSaveAddon()"
            class="button button--primary">
            <span>{{"BUTTON.ADD" | translate}}</span>
        </button>
    </div> 
</div>

