import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
// import { IPlugnPayBodyRequestDTO } from 'src/app/Interfaces/Requests/IPlugnPayBodyRequestDTO';
import { IGeneralResponseDTO } from 'src/app/Interfaces/Responses/GeneralResponseDTO';
// import { IPlugnpayDataApiResponseDTO, IPlugnpayResponseDTO } from 'src/app/Interfaces/Responses/IPlugnPayResponseDTO';
// import { IPlugnpay } from 'src/app/models/Plugnpay.model';
// import { IGetPlugnpayResponseDTO } from 'src/app/models/responses/GetPlugnpayResponse.dto';

import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class FrontDeskService {
  private HostService: string = `${environment.MAIN_URL}${environment.BANKS.FRONT_DESK}`;

  constructor(private http: HttpClient) {}

  // private responseTransformer(
  //   response: IGeneralResponseDTO<IGetPlugnpayResponseDTO>
  // ): IPlugnpay {
  //   let result: IPlugnpay = {
  //     publisher_name: '',
  //     publisher_password: '',
  //   };

  //   response.data.forEach((responseData) => {
  //     result.publisher_name = responseData.banco_cuenta;
  //     result.publisher_password = responseData.banco_afiliacion;
  //   });

  //   return result;
  // }

  // public async isAvailable(bankId: number) {
  //   //: Promise<IPlugnpay>
  //   const observable = this.http.get<IGeneralResponseDTO<IGetPlugnpayResponseDTO>>(`${this.HostService}/${bankId}`);
  //   //.pipe(map((response) => this.responseTransformer(response)));

  //   let response = await lastValueFrom(observable);
  //   //this.paypalSignal.update((paypal) => response);
  //   //this.paypalLoadingSignal.update((loading) => false);

  //   return response;
  // }

  public async executePayment(
    bankId: number,
    reservationId: string
  ): Promise<any> {
    //Promise<IGeneralResponseDTO<IPlugnpayDataApiResponseDTO> | any> {
    //<IPlugnpayResponseDTO>
    const observable = this.http.get(
      `${this.HostService}/${bankId}/${reservationId}`
    );
    //.pipe(map((response) => this.paypalTransformer(response)));

    let response: any = await lastValueFrom(observable);
    // this.paypalSignal.update((paypal) => response);
    // this.paypalLoadingSignal.update((loading) => false);

    return response;
  }

  public async sendConfirmation(reservationId: string): Promise<any> {
    //Promise<IGeneralResponseDTO<IPlugnpayDataApiResponseDTO> | any> {
    //<IPlugnpayResponseDTO>
    const observable = this.http.get(`${environment.MAIN_URL}${reservationId}/confirmation`);
    //.pipe(map((response) => this.paypalTransformer(response)));

    let response: any = await lastValueFrom(observable);
    // this.paypalSignal.update((paypal) => response);
    // this.paypalLoadingSignal.update((loading) => false);

    return response;
  }
}
