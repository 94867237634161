<div class="card">
  <div class="card--header">
    <div class="card--image-block">
      <img class="card--image" [src]="imageUrl" alt="" width="76" height="76" />
    </div>
  </div>
  <div class="card--body">
    <div class="card--description-block">
      <span class="product-title">{{ title }}</span>
      <app-tag [label]="'TRANSPORTATION.ROUND_TRIP' | translate"></app-tag>
    </div>
    <ng-container *ngIf="!showCollapse">
      <div class="button-section" *ngIf="isAddedToCart; then modifyTransportation; else addTransportation"></div>

      <ng-template #addTransportation>
        <button (click)="showCollapseElement()" class="button card--button">
          <span class="button--text">{{ "BUTTON.ADD" | translate }}</span>
        </button>
      </ng-template>
      <ng-template #modifyTransportation>
        <button (click)="showCollapseElement()" class="button card--button">
          {{ "BUTTON.MODIFY" | translate }}
        </button>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="showCollapse">
      <div class="" style="display: flex; flex-direction: column; justify-content: space-between; min-height: 10rem;">
        <div class="">
          <app-select-autocomplete
            [placeholder]="'TRANSPORTATION.SEARCH_HOTEL' | translate"
            [options]="hotels()"
            (onSelectValue)="onSelectHotel($event)"
          ></app-select-autocomplete>
        </div>
        <div class="" style="width: 100%; display: inline-flex; gap: 1rem;">
          <button (click)="onClose()" class="button button--primary-outline">
            {{ "BUTTON.CANCEL" | translate }}
          </button>
          <button (click)="setTransportation()" class="button button--primary">
            {{ "BUTTON.ADD" | translate }}
          </button>          
        </div>
      </div>
      <!--
        <app-select icon="assets/icons/vectors/vector-location.svg" placeholder="Selecciona una zona" [options]="zones()"
        (onSelectValue)="onSelectZone($event)"></app-select>
      -->
      <!--
        <app-select icon="assets/icons/vectors/vector-hotel.svg" placeholder="Busca tu hotel" [options]="hotels()"
        (onSelectValue)="onSelectHotel($event)"></app-select>
      -->

      <!-- <button (click)="onCloseAddon()" class="button button--primary-outline">{{"BUTTON.CANCEL" | translate}}</button>
      <button (click)="setTransportation()" class="button button--primary">
        <span class="button--text">{{ "BUTTON.ADD"| translate}}</span>
      </button> -->
    </ng-container>
  </div>
</div>
