<div class="resume-column">
  <app-shopcar-resume (onPaymentSection)="goToPayment($event)"></app-shopcar-resume>
  <div class="payment-block">
    <p class="title">{{"PAYMENTS.PAYMENTS_ACCEPT" | translate}}</p>
    <div class="payments-methods">
      <img src="assets/payments/visa.svg" alt="">
      <img src="assets/payments/mastercard.svg" alt="">
      <img src="assets/payments/paypal.svg" alt="">
    </div>
  </div>
</div>
<div class="booking-column">
  <h2 class="text-icon-layout section-subtitle section-subtitle--green">
    <mat-icon fontIcon="lock"></mat-icon>
    <span class="section-disclaimer">{{"PAYMENTS.PAYMENT_TRUSTLY" | translate}}</span>
  </h2>
  <div class="interaction-box">
    <app-breadcrumb></app-breadcrumb>
    <div class="continue-box">
      <span class="section-disclaimer">{{"ADDON.SUBTITLE_1" | translate}}</span>
      <div class="button-section">
        <button (click)="goToPayment(true)" class="button button--primary">
          <span class="button--text">{{"BUTTON.CONTINUE" | translate}}</span>
        </button>
      </div>
    </div>
    <!--  -->
    <app-card-transportation *ngIf="showAddonTransportation"
      imageUrl="https://cdn1.dtraveller.net/content/venturapark/widget/selvatica/addon-transportation.jpg"
      [title]="'TRANSPORTATION.TITLE' | translate"
      description="Agrega por un costo adicional tu transportación a las puertas de tu hospedaje."></app-card-transportation>

    <ng-container *ngFor="let addon of addons$ | async ; let index = index">
      <app-addon-card [addon]="addon" (onSelectCollapse)="onSelectAddonCollapse($event,index)"
        (onSelect)="onSelectAddon($event,index)"></app-addon-card>
    </ng-container>
  </div>
</div>
<app-select-pax-mobile *ngIf="showPaxDialog" (onClose)="onClosePaxDialog()" (onSave)="onSaveAddon($event)"
  [paxList]="paxListSelected">
</app-select-pax-mobile>
