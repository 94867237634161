import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app/app.service';
import { ClientService } from 'src/app/services/client/client.service';

@Component({
  selector: 'app-terms-conditions-form',
  templateUrl: './terms-conditions-form.component.html',
  styleUrls: ['./terms-conditions-form.component.css']
})
export class TermsConditionsFormComponent {
  @Output('onValid') onValid : EventEmitter<boolean> = new EventEmitter<boolean>();

  public termsAndConditionsForm : FormGroup = new FormGroup({
    termsConditions : new FormControl(this.clientService.getAcceptTerms(),[Validators.required,Validators.requiredTrue]),
    privacyPolicy : new FormControl(this.clientService.getAcceptPrivacy(),[Validators.required,Validators.requiredTrue])
  });

  private form$! : Subscription;
  public privatePolicyUrl$ : Observable<string> = new Observable<string>();
  public termsConditionsUrl$ : Observable<string> = new Observable<string>();
  public acceptTerms$ : Observable<boolean> = new Observable<boolean>();
  public acceptPrivacy$ : Observable<boolean> = new Observable<boolean>();

  constructor(
    private appService : AppService,
    private clientService : ClientService

  ) {}

  ngOnInit(){
    this.form$ = this.termsAndConditionsForm.statusChanges
      .subscribe(data=>{
        if(data === "VALID"){
          this.onValid.emit(true);
          this.clientService.setAcceptPrivacy(true);
          this.clientService.setAcceptTerms(true);
        } else {
          this.onValid.emit(false);
          this.clientService.setAcceptPrivacy(false);
          this.clientService.setAcceptTerms(false);
        }
      });

    this.privatePolicyUrl$ = this.appService.privatePolicyUrl$;
    this.termsConditionsUrl$ = this.appService.termsConditionsUrl$;
    this.acceptTerms$ = this.clientService.acceptTerms$;
    this.acceptPrivacy$ = this.clientService.acceptPrivacy$;
  }

  ngOnDestroy(){
    this.form$.unsubscribe();
  }

}
