<div class="payment-confirmation-container">
    <div 
        *ngIf="showDeclinedPayment" 
        class="payment-container--block payment-container--icon-block payment-container--icon-block--declined">
        <mat-icon aria-hidden="false" class="icon icon--light icon--size-xl" fontIcon="credit_card_off"></mat-icon>
    </div>
    <div
        *ngIf="showSuccessPayment"  
        class="payment-container--block payment-container--icon-block payment-container--icon-block--aproved">
        <mat-icon aria-hidden="false" class="icon icon--light icon--size-xl" fontIcon="credit_card"></mat-icon>
    </div>
    <div class="payment-container--block">
        <h1 class="text text--primary">{{total | currency:currencyCode:'symbol':'1.2-2' }} {{currencyCode}}</h1>
    </div>
    <!-- success -->
    <div
        *ngIf="showSuccessPayment" 
        class="payment-container--block">
        <p class="text text--success text--bold">Payment Successful</p>
        <p>
            <span class="text text--muted">Transaction Number: </span> 
            <span class="text--primary text--bold">{{paymentReference}}</span>
        </p>
        <p>
            <span class="text text--muted">Card: </span> 
            <span class="text text--primary text--bold">{{cardCompany}}</span>
        </p>
    </div>
    <!-- danger --> 
    <div 
        *ngIf="showDeclinedPayment"
        class="payment-container--block">
        <p class="text text--error text--bold">Payment declined</p>
        <p>
            <span class="text text--muted">Transaction Number: </span> 
            <span class="text--primary text--bold">{{paymentReference}}</span>
        </p>
    </div> 
    <!-- success -->
    <div
        *ngIf="showSuccessPayment" 
        class="payment-container--block">
        <p class="text--primary">A confirmation code will be sent to your email</p>
        <p class="text text--muted text--bold">{{clientEmail}}</p>
    </div>      
    <div class="payment-container--block">
        <button class="button button--primary" [routerLink]="['/reservation-confirmation', paymentReference]">
            done
        </button>
    </div>  
</div>
