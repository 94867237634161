<div class="status">
    <div class="status--image-box">
        <img src="assets/error-forma-de-pago.png" alt="">
    </div>
    <div class="status--text-block">
        <span class="section-title">{{ reservationId }}</span>
    </div>
    <div class="status--text-block">
        <span class="section-title">{{"SUPPORT.TITLE_1" | translate}}</span>
    </div>
    <div class="status--text-block">
        <span class="section-subtitle">{{"SUPPORT.TITLE_2" | translate}}</span>
    </div>
    <div class="status--text-block" *ngIf="reservation">
        <mat-icon aria-hidden="false" fontIcon="support_agent"></mat-icon>
        <span class="section-subtitle">
          <a [href]="hrefTelephone">{{ (reservation != null ? 'SUPPORT.PHONE.' + reservation.locationName : '') | translate }}</a>
        </span>
    </div>

    <div class="status--text-block" *ngIf="reservation">
        <a
            class="button button--success"
            href="{{ (reservation != null ? 'SUPPORT.WHATSAPP.' + reservation.locationName : '') | translate }}"
            target="_blank">
            <span>WHATSAPP</span>
            <mat-icon aria-hidden="false" fontIcon="sms"></mat-icon>
        </a>
    </div>

    <!-- <code [innerHTML]="reservation?.locationName"></code> -->
</div>
