import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IProduct } from 'src/app/models/Product.model';
import { IService } from 'src/app/models/Service.model';

@Component({
  selector: 'app-select-product-new',
  templateUrl: './select-product.component.html',
  styleUrls: ['./select-product.component.css'],
})
export class SelectProductComponent {
  @Input() disabled: boolean = false;
  @Input() productList: IService[] = [];
  @Output('onServiceSelected') onServiceSelected: EventEmitter<IService> =
    new EventEmitter();

  public productForm: FormGroup = new FormGroup({
    product: new FormControl<IService | null>(null),
  });

  ngOnInit() {
    if (this.disabled) this.productForm.disable();

    this.productForm.valueChanges.subscribe((form) => {
      form.product.selected = true;
      this.onServiceSelected.emit(form.product);
    });
  }

  ngOnChanges() {
    let serviceIndex = this.productList.findIndex(
      (product) => product.selected === true
    );
    if (serviceIndex !== -1) {
      this.productForm.setValue({ product: this.productList[serviceIndex] });
    }
  }

  public getProductSelected(): string | undefined {
    return this.productList.find((i) => i.selected)?.name;
  }
}
