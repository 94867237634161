import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { IReservationDTO } from 'src/app/Interfaces/Responses/GetReservationByIdResponse.dto';
import { IReservation } from 'src/app/models/Reservation.model';
import { ReservationService } from 'src/app/services/reservation/reservation.service';

@Component({
  selector: 'app-support-reservation',
  templateUrl: './support-reservation.component.html',
  styleUrls: ['./support-reservation.component.css'],
})
export class SupportReservationComponent {
  public reservationId: string = '4VP-240104-1416661';
  public reservation: IReservationDTO | undefined;

  constructor(
    private reservationService: ReservationService,
    private translateService: TranslateService
  ) {}

  async ngOnInit() {
    this.reservationId = this.reservationService.getReservationId();
    this.reservation = await this.reservationService.getReservation(
      this.reservationId
    );
  }

  public hrefTelephone() {
    let result: string = '';

    let locationName = this.reservation?.locationName;
    let stringTranslation = this.translateService.instant('SUPPORT.PHONE.' + locationName);
    let matches = stringTranslation.match(/\d+/g);


    Array.from(matches).forEach((match) => {
      if (typeof Number(match) == 'number') result += match;
    });

    return `tel:${result}`;
  }
}
