<div class="close-dialog">
    <div class="close-dialog--block">
        <span class="close-dialog--main-title">{{"CLOSE_MODAL.QUESTION" | translate}}</span>
    </div>
    <div class="close-dialog--block">
        <span class="close-dialog--secondary-title">{{"CLOSE_MODAL.DISCLAIMER" | translate}}</span>
    </div>
    <div class="close-dialog--button-block">
        <button 
        (click)="closeApp()"
        class="close-dialog--button close-dialog--button--blue">{{"CLOSE_MODAL.LEAVE" | translate}}</button>
        <button 
        (click)="closeDialog()"
        class="close-dialog--button close-dialog--button--gray">{{"CLOSE_MODAL.STAY" | translate}}</button>
    </div>
</div>