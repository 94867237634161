enum ENDPOINTS_URL 
{    
    WORLD_COUNTRIES_ENDPOINT = 'world/countries',
    WORLD_STATES_ENDPOINT = 'world/countries',
    AUTH_TOKEN = 'token',
    APP_DATA_ENDPOINT = 'app/data',
    SELECT_PRODUCT_ENDPOINT = 'product/select',
    GET_AVAIBILITY_ENDPOINT = 'inventory/avaibility',
    SELECT_SCHEDULES_ENDPOINT = 'schedule/select',
    SELECT_PAX_ENDPOINT = 'pax/select',
    VALIDATE_DISCOUNT ='web/discount',
    SELECT_ADDONS_ENDPOINT = 'addon/list',
    VALIDATE_PRICE_ENDPOINT = 'price/validate',
    CREATE_RESERVATION_ENDPOINT = 'reservation',
    PAYPAL_CREATE_ORDER_ENDPOINT = 'payment/paypal',
    PAYPAL_AUTHORIZE_ORDER_ENDPOINT = 'payment/paypal/authorize-transaction',
    PAYPAL_CAPTURE_ORDER_ENDPOINT = 'payment/paypal/capture-transaction',
    MIT_CREATE_ORDER_ENDPOINT = 'payment/mit/',
    GET_RESERVATION = 'web/reservation/',
    GET_DISCOUNT_ENDPOINT = 'discount/',
    GET_BANK = 'banks'
}

enum CurrencyEnum{
    DOLLAR_US = 'USD',
    PESO_MX = 'MXN' 
}

enum APP_INFO
{
    SELLER_ID = 31,
    LOCATION_ID = 8,
    CURRENCY_ID = 1,
}

enum STATUS_BOOKING
{   
    STEP_ZERO = 0,
    STEP_SELECT_PRODUCT = 1,
    STEP_SELECT_CALENDAR = 2,
    STEP_SELECT_PAX = 3,
    STEP_SELECT_SCHEDULE = 4
}


export{
    ENDPOINTS_URL,
    APP_INFO,
    STATUS_BOOKING,
    CurrencyEnum
}