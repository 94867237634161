<form class="client-form" action="" [formGroup]="termsAndConditionsForm">
    <mat-checkbox 
        formControlName="termsConditions">
        <span class="legacy-disclaimer">
            {{"CLIENT_FORM.READ_AND_ACCEPT" | translate }}
        </span>
        <a class="legacy-disclaimer" [href]="privatePolicyUrl$ | async" target="_blank">
            {{"CLIENT_FORM.TERMS_AND_CONDITIONS_LINK_TEXT" | translate}}
        </a>
    </mat-checkbox>
    <mat-checkbox
        formControlName="privacyPolicy"
        class="legacy-disclaimer">
        <span class="legacy-disclaimer">
            {{"CLIENT_FORM.READ_AND_ACCEPT" | translate }}
        </span>
        <a class="legacy-disclaimer" [href]="termsConditionsUrl$ | async" target="_blank">
            {{"CLIENT_FORM.PRIVACY_LINK_TEXT" | translate}}
        </a>
    </mat-checkbox>
</form>