<div class="resume-block">
    <span class="total-title">{{'RESUME.TOTAL_PRICE' | translate}}</span>
    <span class="total">{{total$ | async | currency}}</span>
    <ng-container *ngIf="shopcart$ | async as shopcart">
        <span class="subtotal" *ngIf="shopcart.shopcartSubtotal > 0">{{shopcart.shopcartSubtotal| currency}}</span>
    </ng-container>
</div>
<button
    [ngClass]="{'disabled' : disabled}"
    (click)="goToAddons()"
    class="button button--primary">
    <span class="button--text">{{"BUTTON.CONTINUE" | translate}}</span>
</button>