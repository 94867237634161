<nav class="breadcrumb">
  <ng-container *ngIf="breadcrumb$ | async as breadcrumb">
    <ng-container *ngFor="let item of breadcrumb; index as index; last as last">
      <ng-container *ngIf="!last">
        <a class="breadcrumb--link breadcrumb--link--inactive" (click)="redirectBreadcrumb(item.link)">{{index + 1}}. {{ item.label }}</a> 
        <span class="breadcrumb--link breadcrumb--link--inactive">></span>
      </ng-container>
      <ng-container *ngIf="last">
        <span class="breadcrumb--link">{{index + 1}}.{{item.label}}</span>
      </ng-container>
    </ng-container>
  </ng-container>
</nav>