<header class="header--light">
  <div class="icon-box" (click)="goToHome()">
    <img id="logo" [src]=" logoUrl | safe:'url' " alt="">
    <!-- 
          <img id="logo" [src]=" logoUrl | safe:'url' " alt="">
    -->
  </div>
  <button 
    (click)="close()"
    class="header__button-close">
    <mat-icon aria-hidden="false" fontIcon="close"></mat-icon>
  </button>
</header>
