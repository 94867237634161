import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPax } from 'src/app/Interfaces/Models/Pax.interface';
import { PaxService } from 'src/app/services/pax/pax.service';
import { TotalsService } from 'src/app/services/totals/totals.service';

@Component({
  selector: 'app-select-pax-mobile',
  templateUrl: './select-pax-mobile.component.html',
  styleUrls: ['./select-pax-mobile.component.css']
})
export class SelectPaxMobileComponent {
  @Input() paxList : IPax[] = [];
  @Output() onSave : EventEmitter<IPax[]> = new EventEmitter();
  @Output() onClose : EventEmitter<boolean> = new EventEmitter();

  public paxListReference : IPax[] = [];

  ngOnChanges(){
    this.paxListReference = [...this.paxList];    
  }

  onCloseDialog(){
    this.onClose.emit(true);
  }

  onSaveAddon(){
    this.onSave.emit(this.paxListReference);
  }

  add(paxIndex : number){
    this.paxList[paxIndex].paxQuantity ++;
  }

  remove(paxIndex : number){
    this.paxList[paxIndex].paxQuantity --;
  }
}
