import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BreadcrumbService } from 'src/app/services/breadcrumb/breadcrumb.service';
import { IBreadcrumb } from 'src/app/models/Breadcrumb.model';
import { RouterService } from 'src/app/services/router/router.service';
import { StepEnum } from 'src/app/models/enums/Step.enum';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent {
  
  public breadcrumb$ : Observable<IBreadcrumb[]> = new Observable<IBreadcrumb[]>();

  constructor(
    private breadcrumbService : BreadcrumbService,
    private routerService : RouterService,
    private router : Router
  ) {}

  ngOnInit(){
    this.breadcrumb$ = this.breadcrumbService.breadcrumb$
  }

  redirectBreadcrumb(url : string){
    if(url.includes('booking')){
      this.routerService.setStep(StepEnum.BOOKING);
      this.routerService.setBlock(false);
    }

    if(url.includes('personal')){
      this.routerService.setStep(StepEnum.USER_FORM);
      this.routerService.setBlock(false);
    }

    if(url.includes('addon')){
      this.routerService.setStep(StepEnum.ADDON);
      this.routerService.setBlock(false);
    }

    this.router.navigateByUrl(url);
  }
}
