<form class="select" [formGroup]="selectForm">
    <div class="select--icon-box">
        <object [data]="icon | safe:'resourceUrl'" type=""></object>
    </div>
    <select id="data" formControlName="data" placeholder="selecciona algo" class="select--input">
        <option value="" disabled selected>
            {{placeholder}}
        </option>
        <ng-container *ngFor="let option of options">
            <option [ngValue]="option.value">{{option.title}}</option>
        </ng-container>
    </select>
</form>