import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { IAddon } from 'src/app/Interfaces/Models/Addon.interface';
import { IPax } from 'src/app/Interfaces/Models/Pax.interface';
import { CurrencyService } from 'src/app/services/currency/currency.service';
import { ICurrency } from 'src/app/models/Currency.model';
import { AddonService } from 'src/app/services/addon/addon.service';

@Component({
  selector: 'app-addon-card',
  templateUrl: './addon-card.component.html',
  styleUrls: ['./addon-card.component.css']
})
export class AddonCardComponent {
  @Input() addon! : IAddon;
  @Output() onSelect : EventEmitter<IPax[]> = new EventEmitter();
  @Output() onSelectCollapse: EventEmitter<IPax[]> = new EventEmitter();

  public currency$ : Observable<ICurrency> = new Observable<ICurrency>;
  public showCollapse : boolean = false;
  public paxList : IPax[] = [];

  constructor(
    private currencyService : CurrencyService,
    private addonService : AddonService
  ) {}

  ngOnInit(){
    this.currency$ = this.currencyService.currency$;
  }

  onCloseCollapse(){
    this.showCollapse = false;
  }

  onAddonCollapse(paxList : IPax[])
  {
    this.addon.paxList = [...paxList];
    this.addon.selected = true;
    this.onSelectCollapse.emit(this.addon.paxList)
    this.showCollapse = false;
  }

  onAddPax(quantity : number, addonId : number)
  {
    this.addonService.validateMaxPaxPerAddon(addonId,quantity);
  }

  onAddAddon(){
    this.addon.selected = true;
    this.onSelect.emit(this.addon.paxList)
  }

  onAddAddonDesktop(){
    this.paxList = [...this.addon.paxList];
    this.showCollapse = true;
  }
}
