import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-iframe',
  templateUrl: './payment-iframe.component.html',
  styleUrls: ['./payment-iframe.component.css']
})
export class PaymentIframeComponent {

}
