import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICurrency } from 'src/app/models/Currency.model';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  private currency : ICurrency = {id : 2 , symbol : "MXN"}
  private currencySubject : BehaviorSubject<ICurrency> = new BehaviorSubject<ICurrency>(this.currency);
  public currency$ : Observable<ICurrency> = this.currencySubject.asObservable();
  
  constructor() { }

  public setCurrency(currency : ICurrency)
  {
    this.currency = currency;
    this.currencySubject.next(currency);
  }
  
}
