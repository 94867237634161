<div class="confirmation-container">
    <div class="confirmation-container--background-block">
        <div class="confirmation-container--titles">
            <span>Reservación: {{ reservationId }}</span>
            <h1>Error al procesar el pago</h1>
        </div>
    </div>
    
    <div class="confirmation-container--card">
        <p class="confirmation-container--text confirmation-container--text--strong"></p>
        <p>¿Que puedo hacer?</p>
        <p>Llama a call center a nuestro numero:<strong></strong></p>
    </div>
</div>