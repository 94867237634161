export enum DiscountTypeEnum{
    PERCENT = 0,
    DOLAR = 1,
    PESO_MXN = 2
}

export enum DiscountRuleEnum {
  DEFAULT = 0,
  NO_MORE_CHILDREN_THAN_ADULTS = 1,
}
