import {
  Component,
  EventEmitter,
  Input,
  Output,
  Signal,
  computed,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Observable } from 'rxjs';

import { SelectOption } from 'src/app/models/types/SelectOption.type';

import { IAddon } from 'src/app/Interfaces/Models/Addon.interface';
import { IPax } from 'src/app/Interfaces/Models/Pax.interface';

import { IZone } from 'src/app/models/Zone.model';
import { IHotel } from 'src/app/models/Hotel.model';
import { ICurrency } from 'src/app/models/Currency.model';

import { ToastService } from 'src/app/services/toast/toast.service';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { AddonService } from 'src/app/services/addon/addon.service';
import { HotelService } from 'src/app/services/hotel/hotel.service';
import { CurrencyService } from 'src/app/services/currency/currency.service';
import { TransportationService } from 'src/app/services/transportation/transportation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card-transportation',
  templateUrl: './card-transportation.component.html',
  styleUrls: ['./card-transportation.component.css'],
})
export class CardTransportationComponent {
  @Input() imageUrl: string = '';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() price: number = 0;
  @Input() currency: string = '';

  @Input() addon!: IAddon;
  @Output() onSelect: EventEmitter<IPax[]> = new EventEmitter();
  @Output() onSelectCollapse: EventEmitter<IPax[]> = new EventEmitter();

  myControl = new FormControl('');

  // signals
  public zones: Signal<SelectOption<IZone>[]> = computed(() =>
    this.zoneService.zones().map((zone) => this.zoneToSelectOption(zone))
  );
  public hotels: Signal<SelectOption<IHotel>[]> = computed(() =>
    this.hotelService.hotels().map((hotel) => this.hotelToSelectOption(hotel))
  );

  public hotelIdSelected: number = 0;

  public currency$: Observable<ICurrency> = new Observable<ICurrency>();
  public showCollapse: boolean = false;
  public paxList: IPax[] = [];

  constructor(
    private currencyService: CurrencyService,
    private addonService: AddonService,
    private zoneService: ZonesService,
    private hotelService: HotelService,
    private transportationService: TransportationService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.currency$ = this.currencyService.currency$;
    this.zoneService.getZones();
    this.hotelService.getHotels();
  }

  public onSelectZone(zone: IZone) {
    this.hotelService.getHotels(zone.name);
  }

  public onSelectHotel(hotel: IHotel) {
    this.hotelIdSelected = hotel.id;
  }

  public async setTransportation() {
    const response = await this.transportationService.getTransportation(
      this.hotelIdSelected
    );

    console.log('setTransportation', response);

    if (response == null)
      this.toastService.showToast(
        this.translateService.instant('TRANSPORTATION.NOT_AVAILABLE_HOTEL'),
        'info'
      );
    else this.showCollapse = false;
  }

  onClose() {
    this.showCollapse = false;
  }

  onAddonCollapse(paxList: IPax[]) {
    this.showCollapse = false;
  }

  onAddPax(quantity: number, addonId: number) {
    this.addonService.validateMaxPaxPerAddon(addonId, quantity);
  }

  onAddAddon() {
    this.addon.selected = true;
    this.onSelect.emit(this.addon.paxList);
  }

  showCollapseElement() {
    this.showCollapse = true;
  }

  onAddAddonDesktop() {
    this.showCollapse = true;
  }

  zoneToSelectOption(item: IZone): SelectOption<IZone> {
    return {
      title: item.name,
      value: item,
    };
  }

  hotelToSelectOption(item: IHotel): SelectOption<IHotel> {
    return {
      title: item.name,
      value: item,
    };
  }

  get isAddedToCart(): boolean {
    return this.transportationService.isAddedToCart();
  }
}
