import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environment/environment';
import {
  ActivatedRoute,
  EventType,
  NavigationEnd,
  Router,
} from '@angular/router';

import { AppService } from './services/app/app.service';
import { ThemeService } from './services/theme/theme.service';
import { BreadcrumbService } from './services/breadcrumb/breadcrumb.service';
import { IConfig } from './Interfaces/States/Config';
// import { CountdownTimerService } from './services/countdown-timer/countdown-timer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  loading$: Observable<boolean> = new Observable();

  public showHeader: boolean = true;
  public showCloseDialog: boolean = false;

  private unsubscribe$ = new Subject<void>();

  public locationName$: Observable<string> = new Observable<string>();
  public logoUrl$: Observable<string> = new Observable<string>();
  public href$: Observable<string> = new Observable<string>();

  public breadcrumbItems: { label: string; link: string }[] = [];

  constructor(
    private appService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private themeService: ThemeService,
    // private countdownTimerService: CountdownTimerService
  ) {
    this.loading$ = new BehaviorSubject<boolean>(true);
  }

  ngOnInit(): void {
    this.locationName$ = this.appService.locationName$;
    this.logoUrl$ = this.appService.logo$;
    this.href$ = this.appService.privatePolicyUrl$;

    this.router.events.subscribe((events) => {
      if (events.type == EventType.NavigationEnd) {
        let dataRoute = this.activatedRoute.snapshot.children;
        for (let event of dataRoute) {
          let link = `${event.routeConfig?.path}`;
          let lang = this.appService.getCurrentLang();

          if (event.queryParams['tk']) {
            link += `?tk=${event.queryParams['tk']}`;
          }

          this.breadcrumbService.addBreadcrumb({
            label: event.data[lang],
            link: link,
          });
        }

        document.querySelector('.global-loading')?.remove();
      }
    });

    this.locationName$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        let locationName = value.replaceAll(' ', '').normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase();
        let hostName = this.themeService.getHostname();
        let urlReferrer = this.themeService.getHostnameReferrer();

        console.log('AppComponent', locationName, hostName, urlReferrer);

        if (urlReferrer) {
          let hostNameReferrer = new URL(urlReferrer).hostname.split('.')[1] ?? locationName;

          if (hostNameReferrer) {
            this.themeService.injectStyleFromUrl(`/assets/themes/${hostNameReferrer}.css`);
            return;
          }
        }

        switch (value) {
          case 'Ventura Park':
            this.themeService.Ventura();
            break;
          case 'Selvatica':
            this.themeService.Selvatica();
            break;
          case 'Cancun':
            this.themeService.Cancun();
            break;
          case 'Selva Mágica':
            this.themeService.SelvaMagica();
            break;
          default:
            break;
        }
      });

    // this.logoUrl$.subscribe((status) => {
    //   setTimeout(() => {
    //     this.loading$ = new BehaviorSubject<boolean>(status == null);
    //   }, 3000);
    // });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {}

  public openCloseDialog(event: boolean) {
    this.showCloseDialog = true;
  }

  public closeDialogEvent(event: boolean) {
    this.showCloseDialog = false;
  }

  public closeAppEvent(event: boolean) {
    let targetOrigin: string = 'UNDEFINED';
    const app = this.appService.getAppConfig();

    switch (app.locationId) {
      case 10: //'Selva Magica'
        targetOrigin = environment.URL_SELVA_MAGICA;
        break;
      case 8: //'Selvatica'
        targetOrigin = environment.URL_SELVATICA;
        break;
      case 7: //'Ventura Park'
        targetOrigin = environment.URL_VENTURA;
        break;
      case 4: //'Riviera Maya'
        targetOrigin = environment.URL_RIVIERA_MAYA;
        break;
      case 2: //'Cancun'
        targetOrigin = environment.URL_CANCUN;
        break;
    }

    window.parent.postMessage('closeModal', targetOrigin);
  }

  public handleCountdownTimerUp(): void {
    // Lógica para limpiar el estado cuando se acabe el tiempo
    console.log('El tiempo se ha terminado, limpiando estado...');
    this.closeAppEvent(true);
    // this.countdownTimerService.stopCountdown();
    // Aquí puedes poner la lógica para limpiar el estado de la reservación
  }
}
