import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBreadcrumb } from 'src/app/models/Breadcrumb.model';
import { RouterService } from '../router/router.service';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private breadcrumbInitialState: IBreadcrumb[] = [];
  private breadcrumbSubject : BehaviorSubject<IBreadcrumb[]> = new BehaviorSubject<IBreadcrumb[]>(this.breadcrumbInitialState);
  public breadcrumb$ : Observable<IBreadcrumb[]> = this.breadcrumbSubject.asObservable();

  constructor(private routerService : RouterService) { }

  public addBreadcrumb(breadcrumb : IBreadcrumb){
    let breadcrumbIndex = this.breadcrumbInitialState.findIndex(currentBreadcrumb => currentBreadcrumb.link == breadcrumb.link);
    
    if(breadcrumbIndex < 0){
      this.breadcrumbInitialState.push(breadcrumb);
      this.breadcrumbSubject.next(this.breadcrumbInitialState);
      return true;
    }

    this.breadcrumbInitialState.splice(breadcrumbIndex+1);
    this.routerService.setStep(breadcrumbIndex + 1);
    return true;    
  }
}
