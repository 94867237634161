import { NgFor, NgIf } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, OnInit, QueryList, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormControlOptions, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import {Observable, map, of, startWith} from 'rxjs';
import { filter } from 'rxjs/operators';

import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SelectOption } from 'src/app/models/types/SelectOption.type';


@Component({
  selector: 'app-select-autocomplete',
  templateUrl: './select-autocomplete.component.html',
  styleUrls: ['./select-autocomplete.component.css'],
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectAutocompleteComponent<T> implements OnInit {
  // @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  @Output() onSelectValue: EventEmitter<T> = new EventEmitter();

  @Input() value: string | undefined = '';
  @Input() icon: string = '';
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() options: SelectOption<T>[] = [];
  @Input() validatorOrOpts: FormControlOptions | any;
  @Input() required: boolean = false;

  filteredOptions: SelectOption<T>[];

  public selectForm!: FormGroup;

  constructor() {
    this.filteredOptions = this.options;
  }

  ngOnInit() {
    this.selectForm = new FormGroup({
      data: new FormControl(this.value),
    });

    if (this.required) {
      this.selectForm.get('data')?.setValidators(Validators.required);
      this.selectForm.updateValueAndValidity();
    }

    this.selectForm.statusChanges.subscribe((status) => {
      if (status == 'VALID' && typeof this.selectForm.value.data == 'object') {
        this.selectValue(this.selectForm.value.data);
      }
    });

    this.filteredOptions = this.options;

    if (this.value) {
      this.selectForm.controls['data'].setValue(this.value);
      this.selectValue({ name: this.value, target: true });
    }
  }

  public displayFn(option: T | any): string {
    if(option && option.name)
      return option.name;
    else
      if (typeof(option) == 'string')
        return option;
      else
        return '';
  }

  public selectValue(value: T | any) {
    if (value.target != undefined && value.name) {
      const _value = value.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      let _option = this.options.find((o) => {
        if (o.title == null) {
          return false;
        } else {
          return o.title
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(_value);
        }
      });
      this.onSelectValue.emit(_option?.value);
    } else {
      this.onSelectValue.emit(value);
    }
  }

  public filter(): void {
    const inputValue = this.selectForm.controls['data'].value;

    var filterValue: string | null = null;

    if (typeof inputValue == 'string' && inputValue.length > 0) {
      filterValue = inputValue
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    }

    this.filteredOptions = this.options.filter((o) => {
      if (o.title == null) {
        return false;
      } else {
        return o.title
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(filterValue ?? '');
      }
    });
  }

  public validateRequired(formControlName: string): boolean {
    const formControl = this.selectForm.get(formControlName);

    if (formControl && formControl.hasError('required')) {
      return true;
    }

    return false;
  }

  public getErrorMessageRequired(formControlName: string) {
    const formControl = this.selectForm.get(formControlName);

    if (formControl && formControl.hasError('required')) {
      return 'You must enter a value';
    }
    return '';
  }
}
