import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authToken : (string | null) = null;

  constructor() { }

  public setToken(token : string){
    this.authToken = token;
  }

  public getAuthToken(){
    return this.authToken;
  }
}
