import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor() {}

  public showToast(title: string, icon: SweetAlertIcon = 'success', options?: SweetAlertOptions | {}) {
    const defaultOptions = {
      toast: true,
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast: any) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    };

    const Toast = Swal.mixin({
      ...defaultOptions,
      ...options,
    });

    Toast.fire({
      icon: icon,
      title: title,
    });
  }
}
