<mat-card
    class="demo-inline-calendar-card ">
    <mat-calendar
        class="border border--light"
        id="calendar"
        #calendar
        [minDate]="starDate" 
        [dateClass]="setOpenDays()"
        (selectedChange)="onSelectedDateChange($event!)"
        [selected]="selectedDate"
    >
    </mat-calendar>
</mat-card>

