import { Component, Input } from '@angular/core';
import { RedirectService } from 'src/app/services/redirect/redirect.service';

@Component({
  selector: 'app-footer-home',
  templateUrl: './footer-home.component.html',
  styleUrls: ['./footer-home.component.css']
})

export class FooterHomeComponent {
  @Input("nextButtonActive") nextButtonActive : boolean = true;
  @Input("subTotal") subTotal : number = 0;
  @Input("total") total : number = 0;
  @Input('currencyCode') currencyCode : string = "";

  constructor(
    private redirectService : RedirectService
  ) {
    
  }

  public goToCheckout(){
    this.redirectService.goToCheckout();
  }
}
