import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IReservation } from 'src/app/Interfaces/Models/Reservation.interface';
import { AppService } from 'src/app/services/app/app.service';
import { ReservationService } from 'src/app/services/reservation/reservation.service';

@Component({
  selector: 'app-reservation-confirmation',
  templateUrl: './reservation-confirmation.component.html',
  styleUrls: ['./reservation-confirmation.component.css']
})
export class ReservationConfirmationComponent {

  public locationAddress : string = "Blvd. Kukulcan Km. 25 Hotel Zone 77500 Cancun, QROO, Mexico.";
  public reservationPromise! : Promise<IReservation>;
  public reservation! : IReservation

  constructor(
    private reservationService : ReservationService,
    private activeRoute : ActivatedRoute,
    private appService : AppService,
  ) {}
    
  async ngOnInit(){
    try {
      this.activeRoute.params.subscribe(async (params)=>{
        //this.reservationPromise = this.reservationService.getReservation(params['reservationId']);
        const {reservationLogoUrl} = await this.reservationService.getReservation(params['reservationId']);
      })
    } catch (error) {
      console.log(error)
    }
  }
}
