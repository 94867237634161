import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { IGeneralResponseDTO } from 'src/app/Interfaces/Responses/GeneralResponseDTO';
import { IPaypal } from 'src/app/models/Paypal.model';
import { IGetPaypalResponseDTO } from 'src/app/models/responses/GetPaypalResponse.dto';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root'
})
export class PaypalService {
  private paypalSignal = signal<IPaypal>({ clientId: "", secret: "" });
  private paypalLoadingSignal = signal<boolean>(true);

  public paypal = this.paypalSignal.asReadonly();
  public paypalLoading = this.paypalLoadingSignal.asReadonly();

  private getPaypalUrl: string = `${environment.MAIN_URL}${environment.BANKS.PAYPAL}`


  constructor(private http: HttpClient) { }

  public async getPaypal(bankId: number): Promise<IPaypal> {
    const observable = this.http.get<IGeneralResponseDTO<IGetPaypalResponseDTO>>(`${this.getPaypalUrl}/${bankId}`).pipe(
      map((response) => this.paypalTransformer(response))
    );

    let response = await lastValueFrom(observable);
    this.paypalSignal.update(paypal => response);
    this.paypalLoadingSignal.update(loading => false);

    return response;
  }

  public paypalTransformer(paypal: IGeneralResponseDTO<IGetPaypalResponseDTO>): IPaypal {
    let paypalFormatted: IPaypal = {
      clientId: "",
      secret: ""
    }

    paypal.data.forEach(paypalItem => {
      paypalFormatted.clientId = paypalItem.banco_cuenta;
      paypalFormatted.secret = paypalItem.banco_afiliacion;
    })

    return paypalFormatted;
  }
}
