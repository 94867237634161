import { TranslateService } from '@ngx-translate/core';

export class COUPON_NOT_APPLICABLE_ERROR extends Error {
  // private translateService: TranslateService = new TranslateService();

  constructor(
    message?: string | undefined,
    options?: ErrorOptions | undefined
  ) {
    super(message, options);

    this.name = 'COUPON_NOT_APPLICABLE';
    // this.message = this.translateService.instant('BOOKING.ERROR_DISCOUNT_NOT_APPLICABLE');
  }
}

export class PRODUCT_IS_NOT_IN_THE_COUPON_ERROR extends Error {
  constructor(
    message?: string,
    options?: ErrorOptions
  ) {
    super(message, options);

    this.name = 'PRODUCT_IS_NOT_IN_THE_COUPON_ERROR';

    // private translateService: TranslateService
    //if(!this.message) this.message =

    // this.message = this.translateService.instant(
    //   'BOOKING.ERROR_DISCOUNT_NOT_APPLICABLE'
    // );
  }
}
