import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IReservationDTO } from 'src/app/Interfaces/Responses/GetReservationByIdResponse.dto';
import { AppService } from 'src/app/services/app/app.service';
import { FrontDeskService } from 'src/app/services/front-desk/front-desk.service';
import { HttpService } from 'src/app/services/http/http.service';
import { ReservationService } from 'src/app/services/reservation/reservation.service';

@Component({
  selector: 'app-front-desk',
  template: `
    <p>{{ 'PAYMENTS.FRONT_DESK.DESCRIPTION' | translate }}</p>
    <button mat-flat-button (click)="onSubmit()">
      <mat-icon [appLoadingButton]="loading" color="white">refresh</mat-icon>
      {{ 'PAYMENTS.FRONT_DESK.BUTTON_LABEL' | translate }}
    </button>
  `,
  styleUrls: ['./front-desk.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrontDeskComponent implements OnInit {
  loading: boolean = false;

  @Input() reservationId: string = '';
  @Input() bankId: number = -1;

  @Output() onSuccess: EventEmitter<void> = new EventEmitter<void>();
  @Output() onError: EventEmitter<any> = new EventEmitter<any>();

  reservationData: IReservationDTO | null;

  constructor(
    private httpService: HttpService,
    private router: Router,
    private appService: AppService,
    private reservationService: ReservationService,
    private frontDeskService: FrontDeskService,
    private httpClient: HttpClient
  ) {
    this.reservationData = null;
  }

  async ngOnInit() {
    let reservation = await this.reservationService.getReservation(
      this.reservationId
    );

    if (reservation) this.reservationData = reservation;
  }

  async onSubmit() {
    this.loading = true;
    try {
      let response = await this.frontDeskService.executePayment(
        this.bankId,
        this.reservationId
      );

      //let DataApiResponse = response.data as IPlugnpayDataApiResponseDTO[];

      // console.log('Form Response', response);

      //let containsAMessage = 'message' in response;
      //let isErrorRequestStatus = response.data[0].Data.RequestStatus.Status == 'ERROR';

      if (
        'message' in response ||
        !('length' in response) ||
        (!response.data['TRUE'] &&
          response.data[0].Data.RequestStatus.Status == 'ERROR')
      ) {
        let message = response.message ?? response.data[0].Data.RequestStatus;
        this.onError.emit(message);
      } else if (response.data.TRUE == 1) {
        // this.frontDeskService.sendConfirmation(this.reservationId);
        this.onSuccess.emit();
      }
    } catch (error) {
      this.onError.emit(error);
    } finally {
      this.loading = false;
    }
  }
}
