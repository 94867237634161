import { Injectable } from '@angular/core';
import { DateTime } from "luxon";

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(
    
  ) { }

  public toString(date : Date|null): string
  {
    const luxonDate = DateTime.fromJSDate(date as Date);
    const stringDate = luxonDate.toFormat('yyyy-LL-dd HH:mm:ss');
    return stringDate;
  }

  public addTime(date : Date, time : Date) : string
  {
    const luxonDate = DateTime.fromJSDate(date);
    const luxonTime = DateTime.fromJSDate(time);
    const stringDate = luxonDate.set({
      hour : luxonTime.hour,
      minute : luxonTime.minute,
      second : luxonTime.second
    }).toFormat('yyyy-LL-dd HH:mm:ss');

    return stringDate;
  }

  public stringToDate(dateString : string): Date{
    const luxonDate = DateTime.fromFormat(dateString,'yyyy-LL-dd HH:mm:ss');
    const jsDate = luxonDate.toJSDate();
    return jsDate;
  }

  public stringToTime(timeString:string) : Date
  {
    const luxonDate = DateTime.fromFormat(timeString,'HH:mm:S');
    const jsDate = luxonDate.toJSDate();

    return jsDate;
  }

  public getFirstDayMonth(date : Date) : Date
  {
    const luxonDate = DateTime.fromJSDate(date);
    const lastDate = luxonDate.startOf('month').toJSDate();
    return lastDate;
  }

  public getLastDayMonth(date : Date) : Date
  {
    const luxonDate = DateTime.fromJSDate(date);
    const lastDate = luxonDate.endOf('month').toJSDate();
    return lastDate;
  }

  public getBeforeDay(date : Date) : Date
  {
    const luxonDate = DateTime.fromJSDate(date);
    const lastDate = luxonDate.minus({day : 1}).toJSDate();
    return lastDate;
  }

  public plusDayToDate(dayQuantity : number, date : Date)
  {
    const luxonDate = DateTime.fromJSDate(date);
    const lastDate = luxonDate.plus({day : dayQuantity}).toJSDate();
    
    return lastDate;
  }

  public plusMinutesToDate(minutes : number, date : Date)
  {
    const luxonDate = DateTime.fromJSDate(date);
    const lastDate = luxonDate.plus({minutes : minutes}).toJSDate();
    
    return lastDate;
  }

  
  public minusMinutesToDate(minutes : number, date : Date)
  {
    const luxonDate = DateTime.fromJSDate(date);
    const lastDate = luxonDate.minus({minutes : minutes}).toJSDate();
    
    return lastDate;
  }

  public parseDateMit(date : string){ 
    const luxonDate = DateTime.fromFormat(date,'d/M/y h:mm:ss');
    const stringDate = luxonDate.toString();
    return stringDate;
  }
}
