<app-loader *ngIf="showLoader"></app-loader>
<div class="resume-column">
  <app-shopcar-resume></app-shopcar-resume>
  <div class="payment-block">
      <p class="title">{{"PAYMENTS.PAYMENTS_ACCEPT" | translate}}</p>
      <div class="payments-methods">
          <img src="assets/payments/visa.svg" alt="">
          <img src="assets/payments/mastercard.svg" alt="">
          <img src="assets/payments/paypal.svg" alt="">
      </div>
  </div>
</div>
<div class="interaction-box">
  <app-breadcrumb></app-breadcrumb>
  <h1 class="section-title">{{"PAYMENTS.PAYMENT_TITLE" | translate}}</h1>
  <h1>
    {{reservationId}}
  </h1>    
  <h2 class="text-icon-layout section-subtitle section-subtitle--green">
      <mat-icon fontIcon="lock"></mat-icon>
      <span class="section-disclaimer">{{"PAYMENTS.PAYMENT_TRUSTLY" | translate}}</span>
  </h2>
  <ng-container *ngIf="reservation$ | async as reservation">
    <div 
    class="info-disclaimer" *ngIf="showPayments == false">
        <span class="section-title">{{ reservation.clientName }}</span>
        <span class="section-title">{{ reservation.clientEmail }}</span>
        <span class="section-subtitle">{{"CHECKOUT.CONFIRMATION_PAYMENT.CONFIRMATION_TEXT" | translate}}</span>
        <span class="section-subtitle">{{"CHECKOUT.CONFIRMATION_PAYMENT.CONFIRMATION_TEXT_2" | translate}}</span>
        <app-terms-conditions-form
        (onValid)="onValidTermsAndConditions($event)"></app-terms-conditions-form>
    </div>
  </ng-container>

  <mat-accordion *ngIf="showPayments">
    <ng-container *ngIf="banks$ | async as bankList">
      <ng-container *ngFor="let bank of bankList">
        <mat-expansion-panel hideToggle *ngIf="bank.type === paymentMethods.PAYPAL && !paypalLoading()">
          <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-radio-button value="1"></mat-radio-button>
                <span class="title">{{'CHECKOUT.PAYMENT_AREA.PAYPAL_LABEL' | translate}}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-paypal [reservationId]="reservationId" [bankId]="bank.id" [amount]="amount" [clientId]="paypalClient()"></app-paypal>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle *ngIf="bank.type === paymentMethods.MIT">
          <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-radio-button value="1"></mat-radio-button>
                <span class="title">{{'CHECKOUT.PAYMENT_AREA.DEBIT_CREDIT_CARD_LABEL' | translate}}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngIf="showPaymentError">
            <div class="disclaimer-layout margin-border">
              <span class="text--error">{{'PAYMENTS.PAYMENT_ERROR' | translate}}</span>
            </div>
          </ng-container>
          <app-mit [url]="mitpayment"></app-mit>
        </mat-expansion-panel>
      </ng-container>
    </ng-container>
  </mat-accordion>
</div>
