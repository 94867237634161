<div class="status">
    <div class="status--image-box">
        <img src="assets/offline.svg" alt="">
    </div>
    <div class="status--text-block">
        <span class="section-title">{{"OFFLINE.TITLE_1" | translate}}</span>
    </div>
    <div class="status--text-block">
        <span class="section-subtitle">{{"OFFLINE.TITLE_2" | translate}}</span>
    </div>
</div>  