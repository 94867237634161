<form autocomplete="off" class="example-form" [formGroup]="selectForm">
  <mat-form-field class="example-full-width" appearance="outline">
    <mat-label>{{label}}</mat-label>
    <input #input type="text" [placeholder]="placeholder" aria-label="autocomplete" matInput formControlName="data"
      (input)="filter()"
      (change)="selectValue($event)"
      (focus)="filter()"
      [matAutocomplete]="auto">
    <mat-icon *ngIf="options.length > 0" matSuffix>search</mat-icon>
    <!-- <p matSuffix style="display: inline;">{{timeLeft}}s left</p> -->
    <mat-spinner *ngIf="options.length == 0" matSuffix diameter="20" style="float: right; margin-right: 10px"></mat-spinner>
    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn">
      <mat-option *ngFor="let option of filteredOptions" [value]="option.value">
        {{option.title}}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="validateRequired('data')">{{ getErrorMessageRequired("data") }}</mat-error>
  </mat-form-field>
</form>
