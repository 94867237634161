<ng-container *ngIf="pax">
    <div class="pax-selector">
        <div class="pax-selector--labels">
            <span class="title title--dark">{{pax.name}}</span>
        </div>
        <div class="number-selector">
            <button [ngClass]="{ 'disabled' : isDisabled('minus') }" (click)="remove()"
                class="button button--circle button--primary-outline">
                -
            </button>
            <span>{{pax.quantity}}</span>
            <button
                [ngClass]="{ 'disabled' : isDisabled('plus') }" (click)="add()"
                class="button button--circle button--primary-outline">
                +
            </button>
        </div>
    </div>
</ng-container>
