import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ENDPOINTS_URL } from 'src/app/config/constants';
import { environment } from 'src/environment/environment';

/*DTO */
import { IGetAvaibilityRequestDTO } from 'src/app/Interfaces/Requests/GetAvailibilityRequestDTO';
import { ListProductRequestDTO } from 'src/app/Interfaces/Requests/ListProductRequestDTO';
import { IListPaxRequestDTO } from 'src/app/Interfaces/Requests/ListPaxRequestDTO';
import { IGetAvaibilityResponseDTO } from 'src/app/Interfaces/Responses/GetAvaibilityResponseDTO';
import { IListProductResponseDTO } from 'src/app/Interfaces/Responses/ListProductResponseDTO';
import { IListPaxResponseDTO } from 'src/app/Interfaces/Responses/ListPaxResponseDTO';
import { IScheduleRequestDTO } from 'src/app/Interfaces/Requests/IListScheduleRequestDTO';
import { ISelectScheduleResponseDTO } from 'src/app/Interfaces/Responses/ISelectScheduleResponseDTO';
import { IGetDiscountResponseDTO } from 'src/app/Interfaces/Responses/IGetDiscountResponseDTO';
import { IListAddonResponseDTO } from 'src/app/Interfaces/Responses/IListAddonResponseDTO';
import { IValidatePriceResponseDTO } from 'src/app/Interfaces/Responses/IValidatePriceResponseDTO';
import { IValidateRequestDTO } from 'src/app/Interfaces/Requests/IValidatePriceRequestDTO';
import { IValidateDiscountRequestDTO } from 'src/app/Interfaces/Requests/IValidateDiscountRequestDTO';
import { ICreateReservationRequestDTO } from 'src/app/Interfaces/Requests/CreateReservationRequestDTO';
import { ICreateReservationResponseDTO } from 'src/app/Interfaces/Responses/CreateReservationResponseDTO';
import { IGenerateSellerTokenRequestDTO } from 'src/app/Interfaces/Requests/GenerateSellerTokenRequestDTO';
import { IGetAppDataResponseDTO } from 'src/app/Interfaces/Responses/GetAppDataResponseDTO';
import { IGetReservationByIdResponseDTO } from 'src/app/Interfaces/Responses/GetReservationByIdResponse.dto';
import { GetDiscountByIdResponse } from 'src/app/Interfaces/Responses/GetDiscountByIdResponse.dto';
import { IGeneralResponseDTO } from 'src/app/Interfaces/Responses/GeneralResponseDTO';
import { IGetBankResponseDTO } from 'src/app/models/responses/GetBankResponse.dto';
import { RouterService } from '../router/router.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private routerService: RouterService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  public async getReservationById(reservationId: string, payment?: boolean): Promise<IGetReservationByIdResponseDTO> {
    try {
      let URL = `${environment.MAIN_URL}${ENDPOINTS_URL.GET_RESERVATION}${reservationId}`;

      if (payment) {
        URL += `?payment=true`;
      }

      const observable$ = this.http
        .get<IGetReservationByIdResponseDTO>(URL)

      const response = await lastValueFrom(observable$);
      return response;
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        const statusCode = error.status;  // Access the HTTP status code
        const errorBody = error.error;  // Access the error body (if available)

        if (statusCode === 400) {
          window.location.href = 'https://www.venturapark.com';
        }

        if (statusCode === 410) {
          window.location.href = 'https://www.venturapark.com';
        }
      }

      throw false;
    }
  }

  public async getCountries() {
    const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.WORLD_COUNTRIES_ENDPOINT}`;
    const observable$ = this.http
      .post<any>(URL, {});

    const response = await lastValueFrom(observable$);
    return response.data.map((country: any) => {
      return { id: country.countryId, name: country.countryName }
    });
  }

  public async getStates(countryId: number) {
    const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.WORLD_STATES_ENDPOINT}/${countryId}/states`;
    const observable$ = this.http
      .get<any>(URL, {});

    const response = await lastValueFrom(observable$);
    return response.data;
  }

  public async getToken(body: IGenerateSellerTokenRequestDTO) {
    const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.AUTH_TOKEN}`;
    const observable$ = this.http
      .post<any>(URL, body);

    const response = await lastValueFrom(observable$);
    return response;
  }

  public async getProductList(params: ListProductRequestDTO): Promise<IListProductResponseDTO> {
    const httpParams = this.setParams(params)
    const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.SELECT_PRODUCT_ENDPOINT}`;
    const observable$ = this.http
      .get<IListProductResponseDTO>(URL, { params: httpParams });

    const response = await lastValueFrom(observable$);
    return response;
  }

  public async getAvaibility(body: IGetAvaibilityRequestDTO): Promise<IGetAvaibilityResponseDTO> {
    const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.GET_AVAIBILITY_ENDPOINT}`;
    const observable$ = this.http
      .post<IGetAvaibilityResponseDTO>(URL, body);

    const response = await lastValueFrom(observable$);
    return response;
  }

  public async getScheduleList(params: IScheduleRequestDTO): Promise<ISelectScheduleResponseDTO> {
    const httpParams = this.setParams(params);

    const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.SELECT_SCHEDULES_ENDPOINT}`;
    const observable$ = this.http
      .get<ISelectScheduleResponseDTO>(URL, { params: httpParams });

    const response = await lastValueFrom(observable$);
    return response;
  }

  public async getPaxList(body: IListPaxRequestDTO): Promise<IListPaxResponseDTO> {
    const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.SELECT_PAX_ENDPOINT}`;
    const observable$ = this.http
      .post<IListPaxResponseDTO>(URL, body);

    const response = await lastValueFrom(observable$);
    return response;
  }

  public async getAddonList(productId: number) {
    const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.SELECT_ADDONS_ENDPOINT}?productId=${productId}`;
    const observable$ = this.http
      .get<IListAddonResponseDTO>(URL);

    const response = await lastValueFrom(observable$);

    return response;
  }

  public async validatePrice(body: IValidateRequestDTO): Promise<IValidatePriceResponseDTO> {
    const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.VALIDATE_PRICE_ENDPOINT}`;
    const observable$ = this.http
      .post<IValidatePriceResponseDTO>(URL, body);

    const response = await lastValueFrom(observable$);

    return response;
  }

  public async validateDiscount(body: IValidateDiscountRequestDTO) {
    const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.VALIDATE_DISCOUNT}`;
    const observable$ = this.http
      .post<IGetDiscountResponseDTO>(URL, body);

    const response = await lastValueFrom(observable$);

    return response;
  }

  public async createReservation(body: ICreateReservationRequestDTO) {
    const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.CREATE_RESERVATION_ENDPOINT}`;
    const observable$ = this.http
      .post<ICreateReservationResponseDTO>(URL, body);

    const response = await lastValueFrom(observable$);

    return response;
  }

  public async createPaypalOrder(
    reservationId: string,
    bankId: number,
    amount?: number
  ) {
    const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.PAYPAL_CREATE_ORDER_ENDPOINT}/${bankId}/${reservationId}?amount=${amount ?? 0}`;
    const observable$ = this.http.get<{
      message: string;
      paypalCheckoutUrl: string;
      transactionId: string;
    }>(URL, {
      withCredentials: true,
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded',
      //   Accept: '*/*',
      // },
    });

    const response = await lastValueFrom(observable$);

    return response;
  }

  public async authorizePaypalOrder(orderId: string) {
    const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.PAYPAL_AUTHORIZE_ORDER_ENDPOINT}`;
    const observable$ = this.http
      .post<any>(URL, { orderId: orderId });

    const response = await lastValueFrom(observable$);

    return response;
  }

  public async capturePaypalOrder(paymentId: string, reservationId: string, bankId: number) {
    const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.PAYPAL_CAPTURE_ORDER_ENDPOINT}`;
    const observable$ = this.http.post<any>(URL, { paymentId, reservationId, bankId }, {
      withCredentials: true,
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded',
      //   'Accept': '*/*'
      // },
    });

    const response = await lastValueFrom(observable$);

    return response;
  }


  public async createMITOrder(
    reservationId: string,
    bankId: number,
    amount?: number
  ) {
    const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.MIT_CREATE_ORDER_ENDPOINT}${bankId}/${reservationId}?amount=${amount ?? 0}`;
    const observable$ = this.http
      .get<any>(URL);

    try {
      const response = await lastValueFrom(observable$);
      return response.paymentUrl;
    } catch (error) {
      return false;
    }
  }

  public async getDiscountById(discountId: number) {
    try {
      const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.GET_DISCOUNT_ENDPOINT}${discountId}`;
      const observable$ = this.http
        .get<GetDiscountByIdResponse>(URL);

      const response = await lastValueFrom(observable$);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async getAppData(token: string) {
    const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.APP_DATA_ENDPOINT}`;
    const observable$ = this.http
      .get<IGetAppDataResponseDTO>(URL, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
    const response = await lastValueFrom(observable$);
    return response;
  }

  public async getBank() {
    // hardcode this value because always are online
    const URL = `${environment.MAIN_URL}${ENDPOINTS_URL.GET_BANK}?bankOffline=0`;
    const observable$ = this.http
      .get<IGeneralResponseDTO<IGetBankResponseDTO>>(URL);

    const response = await lastValueFrom(observable$);

    return response;
  }

  private setParams(params: any): HttpParams {
    let httpParams = new HttpParams();

    for (let key in params) {
      httpParams = httpParams.append(key, params[key]);
    }

    return httpParams
  }

}
