import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/services/app/app.service';
import { HttpService } from 'src/app/services/http/http.service';
import { ReservationService } from 'src/app/services/reservation/reservation.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent {
  
  public email : string = "";
  public reservationId : string = "";

  constructor(
    private appService : AppService,
    private activedRoute : ActivatedRoute,
    private httpService : HttpService
  ) {}

  async ngOnInit(){
    let reservationId = this.activedRoute.snapshot.paramMap.get('reservationId');

    if(reservationId){
      let {data} = await this.httpService.getReservationById(reservationId)  
      this.email = data.clientEmail;
      this.reservationId = data.reservationId;
      this.appService.setLogoUrl(data.reservationLogoUrl);
    }
    
  }
}
