<div class="card-addon">
    <div class="image-box">
        <img class="image-box--addon" [src]="addon.addonImage" alt="" width="76" height="76">
    </div>
    <div class="card-addon--content">
        <span class="product-title">{{addon.addonName}}</span>
        <span class="subtitle subtitle--light">{{addon.addonDescription}}</span>

        <ng-container *ngIf="!showCollapse">

        <div class="card--description">
            <span class="product--price product--price--green">
                {{addon.paxList[0].paxPrice  | currency }}
                <ng-container *ngIf="currency$ | async as currency">
                    {{currency.symbol}}
                </ng-container>
            </span>
            <span class="subtitle subtitle--light">{{ "ADDON.PER" | translate}}</span>
        </div>
            <div class="button-section">
                <!--mobile
                <button (click)="onAddAddon()"
                    class="button button--primary-outline button-addon mobile">
                    <span class="button--text">{{ "BUTTON.ADD"| translate}}</span>
                </button>
                -->

                <!-- desktop -->
                <button (click)="onAddAddonDesktop()"
                class="button card--button">
                    <span class="button--text">{{ "BUTTON.ADD"| translate}}</span>
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="showCollapse">
            <div class="pax-select-collapse">
                <app-select-pax
                    (onClose)="onCloseCollapse()"
                    (onSave)="onAddonCollapse($event)"
                    (onAdd)="onAddPax($event, addon.addonId)"
                    [paxAddonQuantity]="addon.availablePaxes"
                    [paxList]="paxList">
                </app-select-pax>
            </div>
        </ng-container>
    </div>
</div>
