import { Component, EventEmitter, Output } from '@angular/core';
@Component({
  selector: 'app-close-dialog',
  templateUrl: './close-dialog.component.html',
  styleUrls: ['./close-dialog.component.css']
})
export class CloseDialogComponent {
  @Output('closeDialogEvent') closeDialogEvent : EventEmitter<boolean> = new EventEmitter;
  @Output('closeAppEvent') closeAppEvent : EventEmitter<boolean> = new EventEmitter;

  public closeDialog(){
    this.closeDialogEvent.emit(true);
  }

  public closeApp(){
    this.closeAppEvent.emit(true);
  }
}
