import { Component } from '@angular/core';

@Component({
  selector: 'app-resume.view',
  templateUrl: './resume.view.component.html',
  styleUrls: ['./resume.view.component.css']
})
export class ResumeViewComponent {

}
