import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { IDiscountWeek } from 'src/app/models/Discount.model';
import { environment } from 'src/environment/environment';

import { DateService } from 'src/app/services/dates/date.service';
import { BookingService } from 'src/app/services/booking/booking.service';

interface IBookingTravelByLocationId {
  [key: number]: number;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent {
  @ViewChild('calendar') calendar!: MatCalendar<Date>;
  @ViewChild(MatCalendar) calendarH!: MatCalendar<Date>;

  @Output('onChangeMonth') onChangeMonth  = new EventEmitter<{startDate : Date, endDate : Date}> ();
  @Output('onCheckInDateSelected') onCheckInDateSelected : EventEmitter<Date> = new EventEmitter();

  @Input('inventoryList') inventoryList : Date[] = [];
  @Input('inventoryWeek') inventoryWeek : IDiscountWeek | null | any = null;
  @Input('daysInAdvance') daysInAdvance: number | null = 0;

  public selectedDate : Date|null = null;
  public starDate : Date|null = null; //this.dateService.plusDayToDate(environment.BOOKING_TRAVEL.LOCATIONS[],new Date());
  public calendarHide : boolean = false;

  constructor(
    private dateService : DateService,
    private bookingService : BookingService,
  ) {
    const bookingTravelByLocation: IBookingTravelByLocationId = environment.BOOKING_TRAVEL.LOCATIONS;
    const bookingTravel  = bookingTravelByLocation[bookingService.getBooking().principalLocationId];
    this.starDate = this.dateService.plusDayToDate(bookingTravel, new Date());
  }

  onNextClicked(): void {
    this.calendar.activeDate = new Date();
  }

  ngOnInit(){
    if(this.calendar != undefined){
    }
  }

  async ngAfterViewInit(){
    const monthNextBtn = document.querySelectorAll('.mat-calendar-next-button');
    monthNextBtn.forEach(item =>{
      item.addEventListener('click',()=>{
        this.handleNextClicked();
      })
    });
  }

  ngOnChanges()
  {
    if(this.calendar != undefined){
      this.calendar.updateTodaysDate();
    }
  }

  ngDoCheck()
  {

  }

  ngAfterContentInit()
  {

  }

  ngAfterContentChecked()
  {

  }

  ngAfterViewChecked()
  {
  }

  handleNextClicked() : void
  {
    let date = this.calendar.activeDate;
    let startDate = this.dateService.getFirstDayMonth(date);
    const endDate = this.dateService.getLastDayMonth(date);
    this.onChangeMonth.emit({ startDate , endDate});
  }

  public onSelectedDateChange(date: Date) : void
  {
    this.selectedDate = date;
    this.calendar.updateTodaysDate();
    this.calendarHide = true;
    this.onCheckInDateSelected.emit(date);
  }

  /* METHOD TO APPLY THE OPEN DAYS */
  public setOpenDays()
  {
    return (date: Date): MatCalendarCellCssClasses => {
      const indexFinded = this.inventoryList.findIndex(item => new Date(item).getTime() === date.getTime());

      const differenceInDays = this.differenceInDays(new Date(), date);
      const inventoryWeek = this.inventoryWeekOrdered();

      if ((this.daysInAdvance != null && this.daysInAdvance > 0) && differenceInDays < this.daysInAdvance) {
        return 'disable';
      }

      if(inventoryWeek.length > 0){
        const dayIndex = date.getDay();
        return indexFinded >= 0 && inventoryWeek[dayIndex] ? 'day--available' : 'disable';
      } else {
        return indexFinded >= 0 ? 'day--available' : 'disable';
      }
    };
  }

  public setEditMode(){
    this.calendarHide = false;
  }

  private inventoryWeekOrdered(): boolean[] {
    const promotionWeek = this.inventoryWeek; // this.bookingService.getBooking().promotionWeek
    if (promotionWeek) {
      const weekInventory = promotionWeek;
      const orderOfTheWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

      const daysOrdered: boolean[] = [];
      orderOfTheWeek.forEach((dayOfTheWeek) => {
        daysOrdered.push(weekInventory[dayOfTheWeek]);
      });

      return daysOrdered;
    }

    return [];
  }

  private differenceInDays(dateStart: Date, dateEnd: Date){
    let differenceInTime = dateEnd.getTime() - dateStart.getTime();
    return Math.round(differenceInTime / (1000 * 3600 * 24) );
  }
}
