import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { WINDOW } from 'src/app/providers/WINDOW_PROVIDERS';
import { DOCUMENT } from 'src/app/providers/DOCUMENT_PROVIDERS';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private renderer: Renderer2;

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  getHostname(): string {
    return this.window.location.hostname;
  }

  getHostnameReferrer(): string {
    return this.document.referrer;
  }

  injectStyleFromUrl(url: string) {
    const linkElement = this.renderer.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.type = 'text/css';
    linkElement.href = url;
    this.renderer.appendChild(document.head, linkElement);
  }

  Selvatica(url: string = '/assets/themes/selvatica.css') {
    const linkElement = this.renderer.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.type = 'text/css';
    linkElement.href = url;
    this.renderer.appendChild(document.head, linkElement);
  }

  Ventura(url: string = '/assets/themes/venturapark.css') {
    const linkElement = this.renderer.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.type = 'text/css';
    linkElement.href = url;
    this.renderer.appendChild(document.head, linkElement);
  }

  Cancun(url: string = '/assets/themes/dolphinconnectionmx.css') {
    const linkElement = this.renderer.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.type = 'text/css';
    linkElement.href = url;
    this.renderer.appendChild(document.head, linkElement);
  }

  SelvaMagica(url: string = '/assets/themes/selvamagica.css') {
    const linkElement = this.renderer.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.type = 'text/css';
    linkElement.href = url;
    this.renderer.appendChild(document.head, linkElement);
  }
}
