import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormControlOptions, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs/internal/Subscription';
import { IClient } from 'src/app/models/Client.model';
import { ICountry } from 'src/app/models/Country.model';

import { SelectOption } from 'src/app/models/types/SelectOption.type';

import { AppService } from 'src/app/services/app/app.service';
import { ClientService } from 'src/app/services/client/client.service';
import { HttpService } from 'src/app/services/http/http.service';
import { WorldService } from 'src/app/services/world/world.service';


@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css'],
})
export class UserFormComponent {
  private subs: Subscription = new Subscription();

  @Output('onValid') onValid: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public client: IClient | null = null;
  public billingForm!: FormGroup;

  constructor(
    private clientService: ClientService,
    private worldService: WorldService,
    private httpService: HttpService
  ) {
    this.countryOptions = [];
  }

  async ngOnInit() {
    this.subs.add(
      this.countryList$.subscribe((result) => {
        this.countryOptions = this.mapCountriesToSelectOptions(result);
      })
    );

    this.client = this.clientService.getClient();

    this.billingForm = new FormGroup({
      name: new FormControl(this.client.name, [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern('[a-zA-ZÀ-ÖØ-öø-ÿ ]+'),
      ]),
      lastName: new FormControl(this.client.lastName, [
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern('[a-zA-ZÀ-ÖØ-öø-ÿ ]+'),
      ]),
      email: new FormControl(this.client.email, [
        Validators.required,
        Validators.email,
      ]),
      address: new FormControl(this.client.address),
      country: new FormControl(this.client.country, [Validators.required]),
      state: new FormControl('N/A'),
      phone: new FormControl(this.client.phone, [Validators.required]),
      comments: new FormControl(this.client.comments),
    });

    this.billingForm.statusChanges.subscribe((data) => {

      if (data != 'VALID') {
        this.onValid.emit(false);
        return;
      }

      this.clientService.addClient({
        name: this.billingForm.value.name,
        lastName: this.billingForm.value.lastName,
        email: this.billingForm.value.email,
        phone: this.billingForm.value.phone,
        address: this.billingForm.value.address,
        comments: this.billingForm.value.comments,
        country: this.billingForm.value.country,
        state: this.billingForm.value.state.stateName ?? 'N/A',
      });

      this.onValid.emit(true);
    });

    let countries = this.worldService.getCountryList();

    if (countries.length <= 0) {
      countries = await this.httpService.getCountries();
      this.worldService.setCountryList(countries);
    }
  }

  async ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public validateErrors(formControlName: string, errorName: string): boolean {
    const formControl = this.billingForm.get(formControlName);
    const hasError = formControl?.hasError(errorName);

    if (formControl && hasError) {
      return true;
    }

    return false;
  }

  public validateRequired(formControlName: string): boolean {
    const formControl = this.billingForm.get(formControlName);

    if (formControl && formControl.hasError('required')) {
      return true;
    }

    return false;
  }

  public getErrorMessageRequired(formControlName: string) {
    const formControl = this.billingForm.get(formControlName);

    if (formControl && formControl.hasError('required')) {
      return 'You must enter a value';
    }
    return '';
  }

  getErrorMessageEmail() {
    const email = this.billingForm.get('email');
    if (!email) {
      return;
    }

    if (email.hasError('required')) {
      return 'You must enter a value';
    }

    return email.hasError('email') ? 'Not a valid email' : '';
  }

  public getEmail() {
    const email = this.billingForm.get('email');

    if (!email || !email.valid) {
      return true;
    }

    return false;
  }

  get validatorFnToFormControlOptions(): FormControlOptions {
    return {
      validators: this.billingForm.get('country')?.validator,
    };
  }

  //#region Input Country
  public countryList$ = this.worldService.countryList$;
  public stateList$ = this.worldService.stateList$;

  public countryOptions: SelectOption<ICountry>[];

  public onSelectCountry(country: ICountry)
  {
    this.billingForm.controls['country'].setValue(country.name);
  }

  private mapCountriesToSelectOptions(
    arrayCountries: ICountry[] | null
  ): SelectOption<ICountry>[] {
    return arrayCountries
      ? arrayCountries.map((item) => this.countryToSelectOption(item))
      : [];
  }

  private countryToSelectOption(item: ICountry): SelectOption<ICountry> {
    return {
      title: item.name,
      value: item,
    };
  }
  //#endregion
}
