import { Injectable } from '@angular/core';

import { HttpService } from '../http/http.service';

import {
  Klaviyo,
  ApiKeySession,

  ProfileEnum,
  ProfilesApi,
  ProfileCreateQuery,
  ProfileCreateQueryResourceObjectAttributes,

  GlobalApiKeySettings,

  PostProfileResponse,
  // OAuthApi,
  // OAuthCallbackQueryParams,
  // OAuthSession,
  // Pkce,
  // ProfilesApi,
  // TokenStorage,
} from 'klaviyo-api';

// import axios from 'axios';
// import { Axios, AxiosRequestConfig } from "axios/index";
// import { BehaviorSubject, Observable } from 'rxjs';

import { AppService } from '../app/app.service';

@Injectable({
  providedIn: 'root'
})
export class KlaviyoService {

  private initialized : boolean = false;

  private sessionAPI: ApiKeySession;
  private profilesAPI: ProfilesApi;

  private _identify$: ProfileCreateQueryResourceObjectAttributes = {
    'email': 'ventura-testing1@gmail.com',
    'firstName': 'Ventura',
    'lastName': 'Testing',
  };

  public get Identify(): ProfileCreateQueryResourceObjectAttributes {
    return this._identify$;
  }


  constructor(
    private appService : AppService
  ) {
    // new GlobalApiKeySettings("< YOUR API KEY HERE >");
    // new GlobalApiKeySession();

    this.sessionAPI = new ApiKeySession("pk_8866470d0a6c8e4a4cc9866f611e9d0cb9"); //pk_8866470d0a6c8e4a4cc9866f611e9d0cb9 Tf8K5U //VenturaPark SandBox
    this.profilesAPI = new ProfilesApi(this.sessionAPI);

    // const oauthApi = new OAuthApi(process.env.CLIENT_ID, process.env.CLIENT_SECRET, tokenStorage);
  }

  public isInitialized(){
    return this.initialized;
  }

  public Event_Identify(){
    console.log('KlaviyoService: Event_Identify -> init');

    let profile: ProfileCreateQuery = {
      data: {
        type: ProfileEnum.Profile,
        attributes: this.Identify
      }
    };

    this.profilesAPI.createProfile(profile).then(({ response, body }) => {
      console.log('KlaviyoService: Event_Identify -> response', response, body);
    }).catch((error) => {
      console.log('KlaviyoService: Event_Identify -> catch', error);
    });

    // axios({
    //   url: `https://a.klaviyo.com/api/profiles`,
    //   headers: {
    //     "Authorization": `Klaviyo-API-Key ${ this.sessionAPI.apiKey }`,
    //     "revision": `2022-12-15`,
    //     "Access-Control-Allow-Origin": `*`,
    //     "Access-Control-Allow-Methods": `GET,PUT,POST,DELETE,PATCH,OPTIONS`,
    //   },
    //   proxy: {
    //     host: '104.236.174.88',
    //     port: 3128
    //   },
    //   data: {
    //     'email': 'ventura-testing1@gmail.com',
    //     'firstName': 'Ventura',
    //     'lastName': 'Testing',
    //   }
    // });

    console.log('KlaviyoService -> Event_Identify -> end');
  }

  public Event_ViewedProduct(){

  }

  public Event_ViewedItem(){

  }

  public Event_AddedToCart(){
    console.log('KlaviyoService -> Event_AddedToCart');
  }

  public Event_StartCheckout(){

  }

  protected Run(){

  }


  private Reset(){

  }
}
