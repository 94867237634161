<div class="ribbon">
    <div class="ribbon--circle ribbon--circle--white">
        <img src="assets/icons/success-pay.png" alt="">
    </div>
</div>
<div class="confirmation">
    <span class="section-title">{{"CONFIRMATION.THANKS" | translate}}</span>
    <span class="section-subtitle">
        {{"CONFIRMATION.SEND" | translate}} <strong>{{email}}</strong>
    </span>
    <span class="section-subtitle">
        {{"CONFIRMATION.RESERVATION" | translate}}: <strong>{{reservationId}}</strong>
    </span>
</div>