<form class="client-form" action="" autocomplete="off" [formGroup]="billingForm">
  <div class="client-form--name">
    <mat-form-field appearance="outline">
      <mat-label>{{ "CHECKOUT.CLIENT_FORM.NAME" | translate }}</mat-label>
      <input matInput type="text" formControlName="name" />
      <mat-error *ngIf="validateRequired('name')">{{
        getErrorMessageRequired("name")
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ "CHECKOUT.CLIENT_FORM.LAST_NAME" | translate }}</mat-label>
      <input matInput type="text" formControlName="lastName" />
      <mat-error *ngIf="validateRequired('lastName')">{{
        getErrorMessageRequired("lastName")
      }}</mat-error>
    </mat-form-field>
  </div>

  <div class="client-form--email">
    <mat-form-field appearance="outline">
      <mat-label>{{ "CHECKOUT.CLIENT_FORM.EMAIL" | translate }}</mat-label>
      <input matInput formControlName="email" />
      <mat-error *ngIf="getEmail()">{{ getErrorMessageEmail() }}</mat-error>
    </mat-form-field>
    <!--
        <mat-form-field appearance="outline">
            <mat-label>{{'CHECKOUT.CLIENT_FORM.EMAIL_CONFIRMATION' | translate}}</mat-label>
            <input matInput formControlName="confirmEmail" (paste)="(false)">
            <mat-error *ngIf="validateRequired('confirmEmail')">{{getErrorMessageRequired('confirmEmail')}}</mat-error>
            <mat-error *ngIf="validateErrors('confirmEmail','emailMismatch')">Email confirmation does not match</mat-error>
        </mat-form-field>
        -->
  </div>

  <div class="client-form--phone">
    <mat-form-field appearance="outline" >
      <mat-label>{{ "CHECKOUT.CLIENT_FORM.PHONE" | translate }}</mat-label>
      <!-- <input matInput formControlName="phone" type="tel"> -->
      <ngx-mat-intl-tel-input
        #phone
        formControlName="phone"
        name="phone"
        [cssClass]="'custom'"
        [required]="true"
        [preferredCountries]="['mx', 'us']"
        [enablePlaceholder]="true"
        [enableSearch]="true"
        >
      </ngx-mat-intl-tel-input>
      <!-- <mat-hint>e.g. {{phone.selectedCountry.placeHolder}}</mat-hint> -->
      <mat-error *ngIf="validateRequired('phone')">{{ getErrorMessageRequired("phone") }}</mat-error>
    </mat-form-field>
  </div>

  <div class="client-form--localization">
    <app-select-autocomplete
      [value]="client?.country"
      [label]="'CHECKOUT.CLIENT_FORM.COUNTRY' | translate"
      [options]="countryOptions"
      [required]="true"
      (onSelectValue)="onSelectCountry($event)"
    ></app-select-autocomplete>
  </div>

  <div class="client-form--comments">
    <mat-form-field appearance="outline">
      <mat-label>{{ "CHECKOUT.CLIENT_FORM.COMMENTS" | translate }}</mat-label>
      <textarea
        matInput
        rows="2"
        cols="5"
        formControlName="comments"
      ></textarea>
    </mat-form-field>
  </div>
</form>

<!-- <code [innerHTML]="client | json"></code> -->
