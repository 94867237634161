import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { SelectOption } from 'src/app/models/types/SelectOption.type';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent<T> {
  @Input() options: SelectOption<T>[] = [];
  @Input() placeholder: string = "";
  @Input() icon: string = "";
  @Output() onSelectValue: EventEmitter<T> = new EventEmitter();

  public selectForm: FormGroup = new FormGroup({
    data: new FormControl('', Validators.required),
  });

  ngOnInit() {
    this.selectForm.statusChanges.subscribe(status => {
      if (status == "VALID") {
        this.selectValue(this.selectForm.value.data);
      }
    });
  }

  public selectValue(value: T) {
    this.onSelectValue.emit(value);
  }
}
