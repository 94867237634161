<div class="resume-column">
    <app-shopcar-resume></app-shopcar-resume>
    <div class="payment-block">
        <p class="title">{{"PAYMENTS.PAYMENTS_ACCEPT" | translate}}</p>
        <div class="payments-methods">
            <img src="assets/payments/visa.svg" alt="">
            <img src="assets/payments/mastercard.svg" alt="">
            <img src="assets/payments/paypal.svg" alt="">
        </div>
    </div>
</div>
<div class="booking-column">
    <h2 class="text-icon-layout section-subtitle section-subtitle--green">
        <mat-icon fontIcon="lock"></mat-icon>
        <span class="section-disclaimer">{{"PAYMENTS.PAYMENT_TRUSTLY" | translate}}</span>
    </h2>
    <div class="interaction-box">
        <app-breadcrumb></app-breadcrumb>
        <h2 class="section-title">{{"CLIENT_FORM.TITLE" | translate}}</h2>
        <span class="subtitle subtitle--light">*{{"CLIENT_FORM.REQUIRED_SUBTITLE" | translate}}</span>  
        <app-user-form (onValid)="onValidClientForm($event)"></app-user-form>
        <div class="disclaimer">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.25 9.5625C11.25 9.2535 11.5035 9 11.8125 9H12.1875C12.4965 9 12.75 9.2535 12.75 9.5625V9.9375C12.75 10.2465 12.4965 10.5 12.1875 10.5H11.8125C11.6635 10.4994 11.5208 10.44 11.4154 10.3346C11.31 10.2292 11.2506 10.0865 11.25 9.9375V9.5625ZM13.5 15H10.5V14.25H11.25V12H10.5V11.25H12.75V14.25H13.5V15Z" fill="#18202D"/>
                <path d="M12 6C10.4087 6 8.88258 6.63214 7.75736 7.75736C6.63214 8.88258 6 10.4087 6 12C6 13.5913 6.63214 15.1174 7.75736 16.2426C8.88258 17.3679 10.4087 18 12 18C13.5913 18 15.1174 17.3679 16.2426 16.2426C17.3679 15.1174 18 13.5913 18 12C18 10.4087 17.3679 8.88258 16.2426 7.75736C15.1174 6.63214 13.5913 6 12 6ZM12 16.875C10.7071 16.875 9.46709 16.3614 8.55285 15.4471C7.63861 14.5329 7.125 13.2929 7.125 12C7.125 10.7071 7.63861 9.46709 8.55285 8.55285C9.46709 7.63861 10.7071 7.125 12 7.125C13.2929 7.125 14.5329 7.63861 15.4471 8.55285C16.3614 9.46709 16.875 10.7071 16.875 12C16.875 13.2929 16.3614 14.5329 15.4471 15.4471C14.5329 16.3614 13.2929 16.875 12 16.875Z" fill="#18202D"/>
                </svg>        
            <span class="disclaimer--text">{{ "CLIENT_FORM.CHECK_IN_DISCLAIMER" | translate}}</span>
        </div>
        <app-terms-conditions-form
        (onValid)="onValidTermsAndConditions($event)"></app-terms-conditions-form>
        <div class="button-section">
            <button
                [ngClass]="{'disabled' : !clientFormValid || !termsAndConditionsValid}"
                (click)="goToNext()" 
                class="button button--primary mobile">
                <span class="button--text">{{ "BUTTON.CONTINUE" | translate }}</span>
            </button>
        </div>
    </div>
</div>