<div id="main-container">
  <ng-container *ngIf="logoUrl$ | async as logoUrl">
    <app-header [logoUrl]="logoUrl" [href]="(href$ | async) ?? 'javascript:void(0);'" (closeEvent)="openCloseDialog($event)">
    </app-header>
  </ng-container>
  <app-close-dialog
    *ngIf="showCloseDialog"
    (closeAppEvent)="closeAppEvent($event)"
    (closeDialogEvent)="closeDialogEvent($event)"
  >
  </app-close-dialog>
  <router-outlet></router-outlet>
  <!-- <app-loader *ngIf="(loading$ | async)"></app-loader> -->
  <!-- <app-loader></app-loader> -->
  <div></div>
</div>
