import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appLoadingButton]',
  standalone: true,
})
export class LoadingButtonDirective {
  

  @Input() set appLoadingButton(isLoading: boolean) {
    if (isLoading) {
      this.renderer.setProperty(this.el.nativeElement, 'disabled', true);
      this.renderer.addClass(this.el.nativeElement, 'loading');
      // this.el.nativeElement.innerText = 'Cargando...'; // Cambiar el texto del botón
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'loading');
      this.renderer.setProperty(this.el.nativeElement, 'disabled', false);
      // this.el.nativeElement.innerText = 'Click aquí'; // Restablecer el texto
    }
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  /*
  @Input() set appLoadButton(load: boolean) {
    if (load) {
      this.renderer.setStyle(this.el.nativeElement, 'backgroundColor', 'grey');
      this.renderer.setProperty(this.el.nativeElement, 'disabled', true);
      this.renderer.setProperty(
        this.el.nativeElement,
        'innerText',
        'Cargando...'
      );
    } else {
      this.renderer.removeStyle(this.el.nativeElement, 'backgroundColor');
      this.renderer.setProperty(this.el.nativeElement, 'disabled', false);
      this.renderer.setProperty(
        this.el.nativeElement,
        'innerText',
        this.defaultText
      );
    }
  }

  private defaultText: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.defaultText = this.el.nativeElement.innerText; // Guarda el texto original del botón
  }
  */
}
