    <div class="reservation-confirm-card" *ngIf="reservationPromise | async as reservation else loading">
    <div class="reservation-confirm-card--header">
        <img src="" alt="">
    </div>
    <div class="reservation-confirm-card--qr-block">
        <img class="image reservation-confirm-card--qr" [src]="reservation.reservationLogoUrl" alt="">
    </div>
    <div class="confirm-card--disclaimer-block">
        <p>
            <span class="text">{{'PURCHASE_CONFIRMATION.RESERVATION' | translate}}: </span>
            <span class="text text--bold">{{ reservation.reservationId }}</span>
        </p>
        <p>
            <span>{{'PURCHASE_CONFIRMATION.HELLO' | translate}},</span>
            <span class="text text--bold">{{ reservation.clientName }}</span>
        </p>
        <p class="text text--muted">
            **{{'PURCHASE_CONFIRMATION.DISCLAIMER_1' | translate}}
        </p>
        <p class="text text--muted">
            **{{'PURCHASE_CONFIRMATION.DISCLAIMER_2' | translate}}
        </p>
    </div>
    <div class="confirm-card--general-info-block">
        <div class="confirm-card--info-block">
            <p class="text text--bold">
                {{'PURCHASE_CONFIRMATION.BOOKING_ID' | translate}}
            </p>
            <mat-divider></mat-divider>
            <p>{{reservation.reservationId}}</p>
            <mat-divider></mat-divider>
        </div>
        <div class="confirm-card--info-block">
            <p class="text text--bold">
                {{'PURCHASE_CONFIRMATION.PAYMENT' | translate}}
            </p>
            <mat-divider></mat-divider>
            <p>{{reservation.paymentType}}</p>
            <mat-divider></mat-divider>
        </div>
        <div class="confirm-card--info-block">
            <p class="text text--bold">
                {{'PURCHASE_CONFIRMATION.DATE' | translate}}
            </p>
            <mat-divider></mat-divider>
            <p>{{reservation.reservationDate | date:'medium'}}</p>
            <mat-divider></mat-divider>
        </div>
        <div class="confirm-card--info-block">
            <p class="text text--bold">
                {{'PURCHASE_CONFIRMATION.BILLING_ADDRESS' | translate}}
            </p>
            <mat-divider></mat-divider>
            <p>{{reservation.clientAddress}}</p>
            <mat-divider></mat-divider>
        </div>
    </div>
    <div class="reservation-confirm-card--product-list-block">
        <div class="reservation-confirm-card--product-header">
            <span class="text text--bold">
                {{'PURCHASE_CONFIRMATION.SERVICE' | translate}}
            </span>
            <span class="text text--bold">
                {{'PURCHASE_CONFIRMATION.TOTAL' | translate}}
            </span>
        </div>
        <div
            *ngFor="let product of reservation.productList" 
            class="reservation-confirm-card--product-body">
            <span class="text text--bold">{{product.productName}}</span>
            <span>{{product.paxQuantity}} {{product.paxType}}</span>
            <span>{{reservation.reservationDate | date:'medium'}}</span>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div
        *ngIf="reservation.addonList.length > 0" 
        class="reservation-confirm-card--product-list-block">
        <div class="reservation-confirm-card--product-header">
            <span class="text text--bold">
                {{'PURCHASE_CONFIRMATION.ADDON' | translate}}
            </span>
            <span class="text text--bold">
                {{'PURCHASE_CONFIRMATION.TOTAL' | translate}}
            </span>
        </div>
        <div
            *ngFor="let addon of reservation.addonList"  
            class="reservation-confirm-card--product-body">
            <span class="text text--bold">{{addon.productName}}</span>
            <span>{{addon.paxQuantity}} {{addon.paxType}}</span>
            <span>{{reservation.reservationDate | date:'medium'}}</span>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="reservation-confirm-card--total-block">
        <p  class="text text--bold">
            {{'PURCHASE_CONFIRMATION.TOTAL' | translate}}: {{reservation.reservationTotal | currency:reservation.currencyCode}} {{reservation.currencyCode}}
        </p>
    </div>
    <mat-divider></mat-divider>
    <div class="reservation-confirm-card--address-block">
        <p class="text text--bold">
            {{'PURCHASE_CONFIRMATION.HOW_TO_GET_THERE' | translate}}
        </p>
        <p>
            {{locationAddress}}
        </p>
        <a target="_blank" href="https://www.google.com/maps/place/Dolphinaris+Cancún/@21.0242746,-86.8156143,261m/data=!3m1!1e3!4m6!3m5!1s0x8f4c29765b0ed64f:0xd982cc04c1edaeeb!8m2!3d21.0245621!4d-86.8147912!16s%2Fg%2F1yj4k3s2j?entry=ttu">Google maps link.</a>
        <mat-divider></mat-divider>
    </div>
    <div>
        <p class="text text--bold">
            {{'PURCHASE_CONFIRMATION.CHANGES_CANCELLATIONS_TITLE' | translate}}:
        </p>
        <p>
            {{'PURCHASE_CONFIRMATION.CHANGES_CANCELLATIONS_TEXT' | translate}}    
        </p>
        <ul>
            <li>
                {{'PURCHASE_CONFIRMATION.CHANGES_CANCELLATIONS_LI_1' | translate}}    
            </li>
            <li>
                {{'PURCHASE_CONFIRMATION.CHANGES_CANCELLATIONS_LI_2' | translate}}    
            </li>
            <li>
                {{'PURCHASE_CONFIRMATION.CHANGES_CANCELLATIONS_LI_3' | translate}}    
            </li>
        </ul>
        <p>
            Tel MEX: 800 733 4042
        </p>
        <p>
            Tel: 981 193 3362 
        </p>
        <p class="text text--bold">
            {{'PURCHASE_CONFIRMATION.WEATHER_GUARANTEE' | translate}}    
            :
        </p>
        <p>
            {{'PURCHASE_CONFIRMATION.WEATHER_GUARANTEE_TEXT' | translate}}        
        </p>
        <p class="text text--bold">
            {{'PURCHASE_CONFIRMATION.PRESENTATION' | translate}}:
        </p>
        <p>
            {{'PURCHASE_CONFIRMATION.PRESENTATION_TEXT' | translate}}
        </p>
        <p class="text text--bold">
            {{'PURCHASE_CONFIRMATION.TRANSPORTATION' | translate}}:
        </p>
        <p>
            {{'PURCHASE_CONFIRMATION.TRANSPORTATION_TEXT' | translate}}
        </p>
    </div>
    <div>
        <p class="text text--muted">
            {{'PURCHASE_CONFIRMATION.DISCLAIMER_END' | translate}}    
        </p>
    </div>
</div>

<ng-template #loading>Loading...</ng-template>

<!--
<div class="confirm-screen">
    <div class="confirm-screen--ribbon confirm-screen--ribbon--green">

    </div>
    <div class="success-icon">
        <img class="success-icon--img" src="assets/success-check.svg" alt="">
    </div>
    <div class="confirm-screen--information">
        <span class="confirm-screen--title"> Thank you for your purchase!</span>
        <span class="confirm-screen--disclaimer">A confirmation email has been sent to {{reservation.reservationEmail}}</span>
        <span class="confirm-screen--order-title">Order {{reservation.reservationId}}</span>
        <button class="confirm-button">
            <span class="confirm-button--title">Accept</span>
        </button>
    </div>
</div>

-->