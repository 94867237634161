import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { environment } from 'src/environment/environment';
import { BankTypeEnum } from 'src/app/models/enums/Bank.enum';
import { BehaviorSubject } from 'rxjs';
import { IGetBankResponseDTO } from 'src/app/models/responses/GetBankResponse.dto';
import { AppService } from '../app/app.service';

interface IBanksByLocationId {
  [key: number]: string[];
}

@Injectable({
  providedIn: 'root',
})
export class BanksService {
  private bankList: { id: number; type: BankTypeEnum, status: boolean }[] = [];
  private bankSubject = new BehaviorSubject(this.bankList);
  public bank$ = this.bankSubject.asObservable();

  constructor(
    private httpService: HttpService,
    private appService: AppService
  ) {}

  public async getBanks() {
    let locationId: number = this.appService.getLocation().locationId;
    const banks: IBanksByLocationId = environment.BANKS_AVAILABLE.LOCATIONS;

    const { data, length } = await this.httpService.getBank();
    const banksAvailable  = banks[locationId];
    const banksEnabled = [];

    if (banksAvailable != null) {
      for (let type of banksAvailable) {
        let bankIndex = data.findIndex((bank) => bank.bankType == type);
        if (bankIndex >= 0) {
          banksEnabled.push(data[bankIndex]);
        }
      }

      this.bankList = banksEnabled.map((bank) => {
        return { id: bank.bankId, type: bank.bankType, status: true };
      });
    } else {
      this.bankList = data.map(bank => {
        return { id: bank.bankId, type: bank.bankType, status: true }
      });
    }

    this.bankSubject.next(this.bankList);

    return this.bankList;
  }
}
