import {
  Component,
  OnDestroy,
  OnInit,
  Signal,
  computed,
  signal,
} from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { IReservation } from 'src/app/Interfaces/Models/Reservation.interface';
import { BankTypeEnum } from 'src/app/models/enums/Bank.enum';
import { StepEnum } from 'src/app/models/enums/Step.enum';
import { BanksService } from 'src/app/services/banks/banks.service';
import { HttpService } from 'src/app/services/http/http.service';
import { PaypalService } from 'src/app/services/paypal/paypal.service';
import { ReservationService } from 'src/app/services/reservation/reservation.service';
import { RouterService } from 'src/app/services/router/router.service';
import { IPaypal } from 'src/app/models/Paypal.model';
// // import { PlugnpayService } from 'src/app/services/plugnpay/plugnpay.service';
import { CountdownTimerService } from 'src/app/services/countdown-timer/countdown-timer.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ClientService } from 'src/app/services/client/client.service';
import { ShopcartService } from 'src/app/services/shopcart/shopcart.service';
import { AppService } from 'src/app/services/app/app.service';
import { AddonService } from 'src/app/services/addon/addon.service';
import { BookingService } from 'src/app/services/booking/booking.service';

@Component({
  selector: 'app-payment.view',
  templateUrl: './payment.view.component.html',
  styleUrls: ['./payment.view.component.css'],
})
export class PaymentViewComponent implements OnInit, OnDestroy {
  public paymentMethodOpened = signal<string | null>(null);
  public paymentGatewaySignal = signal<BankTypeEnum | null>(null);
  public paymentMethods = BankTypeEnum;
  public reservationId: string = '';
  public reservation!: IReservation;

  public mitPaymentShow: boolean = false;
  public mitpayment: string = '';

  public showLoader: boolean = true;
  public showPaymentError: boolean = false;
  public showPayments: boolean = true;

  // observers-
  public banks$ = this.bankService.bank$;
  public reservation$ = this.reservationService.reservation$;

  // subscriptions
  public firebaseSubscription: Subscription = new Subscription();

  //signals
  public paypalClient = computed(() => this.paypalService.paypal().clientId);
  public paypalLoading = this.paypalService.paypalLoading;

  constructor(
    private bankService: BanksService,
    private activedRoute: ActivatedRoute,
    private toastService: ToastService,
    private translateService: TranslateService,
    private reservationService: ReservationService,
    private firebaseRealTimeService: AngularFireDatabase,
    private httpService: HttpService,
    private router: Router,
    private routerService: RouterService,
    private paypalService: PaypalService,
    // private plugnpayService: PlugnpayService,
    private countdownTimerService: CountdownTimerService,

    private appService: AppService,
    private clientService: ClientService,
    private shopcartService: ShopcartService,
    private addonService: AddonService,
    private bookingService: BookingService
  ) {}

  async ngOnInit(): Promise<void> {
    this.countdownTimerService.startCountdown(900);

    this.routerService.setStep(StepEnum.PAYMENT);
    this.reservationId = this.reservationService.getReservationId();

    if (this.reservationId == '') this.CreatingReservation();

    // logic for payment url
    let reservationParam =
      this.activedRoute.snapshot.paramMap.get('reservationId');

    if (reservationParam) {
      this.showPayments = false;
      this.reservationId = reservationParam;
      await this.reservationService.setUpReservation(reservationParam);
    }

    // check payment methods available
    let bankList = await this.bankService.getBanks();

    for await (let bank of bankList) {
      if (bank.type === this.paymentMethods.MIT) {
        let responseCreateOrderMIT = await this.httpService.createMITOrder(
          this.reservationId,
          bank.id
        );

        if (typeof responseCreateOrderMIT == 'string') {
          this.mitPaymentShow = true;
          this.mitpayment = responseCreateOrderMIT;

          this.firebaseSubscription = this.firebaseRealTimeService
            .object(`reservations/${this.reservationId}`)
            .valueChanges()
            .subscribe(async (data: any) => {
              // if the payments attempts is more than 3 or equal, always send to support page
              if (data['paymentAttempts'] >= 3) {
                this.reservationService.setReservationId(data['reservationId']);
                this.router.navigate(['/support-reservation']);
              }

              // if the payment status are true send to confirm page
              if (data['paymentStatus']) {
                this.showPaymentError = false;
                this.router.navigate([
                  '/reservation-confirmation',
                  data.reservationId,
                ]);
              }

              if (data['paymentError']) {
                this.mitpayment = await this.httpService.createMITOrder(
                  this.reservationId,
                  bank.id
                );
                this.showPaymentError = true;
              }
            });
        } else {
          bank.status = false;
          // bankList.push(bank);
        }
      }

      if (bank.type == this.paymentMethods.PAYPAL) {
        await this.paypalService.getPaypal(bank.id);
      }

      // //   if (
      // //     bank.type == this.paymentMethods.CARD
      // //     // &&
      // //     // ['PnP'].some((i) => bank..includes(i))
      // //   ) {
      // //     let responseAvailable = await this.plugnpayService.isAvailable(bank.id);

      // //     if (responseAvailable && responseAvailable.data) {
      // //       bank.status = true;
      // //     }
      // //   } else bank.status = false;

      if (bank.type == this.paymentMethods.FRON_DESK) {
        // bank.status = true;
      }
    }

    let isOnlyOneProcessorAvailable =
      bankList.filter((i) => i.status).length == 1;

    if (isOnlyOneProcessorAvailable)
      this.setPaymentGateway(
        bankList.filter((i) => i.status).at(0)?.type ?? null
      );

    this.showLoader = false;
  }

  ngOnDestroy() {
    this.firebaseSubscription.unsubscribe();
  }

  async CreatingReservation() {
    try {
      let clientForm = this.clientService.getClient();
      let shopcart = this.shopcartService.getShopcart();

      let reservationId = await this.reservationService.createReservation(
        shopcart,
        clientForm
      );

      if (reservationId) {
        this.reservationService.setReservationId(reservationId);
        this.reservationId = this.reservationService.getReservationId();
      } else {
        //${this.reservationService.limitForInventory}
        this.toastService.showToast(
          `${this.translateService.instant('SUPPORT.NO_INVENTORY')}`,
          'info',
          { timer: 5000 }
        );
      }
    } catch (error: HttpErrorResponse | any) {
      console.log('AddonViewComponent.goToPayment =>', error);

      if (error && error.status == 400 && error.error.reservationTotal) {
        let msg = `${this.translateService.instant(
          'SUPPORT.TITLE_3'
        )} \n ${this.translateService.instant(
          'SUPPORT.PHONE.' + this.appService.getLocation().locationName
        )}`;
        this.toastService.showToast(msg, 'info', { timer: 5000 });
      }
    }
  }

  onValidTermsAndConditions(event: any) {
    this.showPayments = true;
  }

  private setPaymentGateway(paymentGateway: BankTypeEnum | null) {
    this.paymentGatewaySignal.set(paymentGateway);
  }

  public handleCountdownTimerUp(): void {
    this.toastService.showToast(
      `${this.translateService.instant('PAYMENTS.PAYMENT_TIMER')}`,
      'info',
      { timer: 5000 }
    );

    this.countdownTimerService.stopCountdown();
    this.routerService.goToBooking();
  }

  onPaymentSucces(event: any) {
    this.router.navigate(['/reservation-confirmation', this.reservationId]);
  }

  onPaymentError(event: any) {
    console.log('onPaymentError', event);
    // if (data['paymentError']) {
    //   this.mitpayment = await this.httpService.createMITOrder(
    //     this.reservationId,
    //     bank.id
    //   );
    //   this.showPaymentError = true;
    // }
  }

  async onPaymentSuccessFrontDesk() {
    const { clientName } = await this.reservationService.getReservation(
      this.reservationId
    );
    let message = this.translateService.instant('CONFIRMATION.SEND');
    this.toastService.showToast(message + ' ' + clientName, 'success');
    this.addonService.cleanState();
    this.bookingService.cleanState();
    this.shopcartService.cleanState();
    this.routerService.goToBooking();
  }

  onPaymentErrorFrontDesk() {
    // let message = this.translateService.instant('CONFIRMATION.SEND');
    // this.toastService.showToast(message, 'error');
  }

  onPanelOpened() {
    this.paymentMethodOpened;
  }
}
