import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { RedirectService } from 'src/app/services/redirect/redirect.service';
import { ShopcartService } from 'src/app/services/shopcart/shopcart.service';
import { CurrencyService } from 'src/app/services/currency/currency.service';
import { RouterService } from 'src/app/services/router/router.service';
import { ICurrency } from 'src/app/models/Currency.model';
import { IShopcart } from 'src/app/models/Shopcart.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  @Input() disabled : boolean = true;
  public total$! : Observable<number>;
  public currency$! : Observable<ICurrency>;
  public shopcart$ : Observable<IShopcart> = new Observable();
  
  constructor(
    private routerService : RouterService,
    private shopcartService : ShopcartService,
    private currencyService : CurrencyService
  
    ) {
    
  }

  ngOnInit(){
    this.total$ = this.shopcartService.total$;
    this.currency$ = this.currencyService.currency$;
    this.shopcart$ = this.shopcartService.shopcart$;
  }

  public goToAddons(){
    this.routerService.goToClientForm();
  }
}
