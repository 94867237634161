import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICountry } from 'src/app/models/Country.model';
import { IState } from 'src/app/models/State.model';

@Injectable({
  providedIn: 'root'
})
export class WorldService {
  
  private countryList : ICountry[] = [];
  private countryListSubject = new BehaviorSubject(this.countryList);
  public countryList$ = this.countryListSubject.asObservable();

  private stateList : IState[] = [];
  private stateListSubject = new BehaviorSubject(this.stateList);
  public stateList$ = new BehaviorSubject(this.stateListSubject.asObservable());


  constructor() { }


  setCountryList(countryLit : ICountry[]){
    this.countryListSubject.next(countryLit);
  }

  getCountryList(){
    return this.countryListSubject.value;
  }

  setStateList(stateList : IState[]){
    this.stateListSubject.next(stateList);
  }

  getStateList(){
    return this.stateListSubject.value;
  }
}
