import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { StepEnum } from 'src/app/models/enums/Step.enum';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environment/environment';

interface IWizardByLocation {
  [key: string]: string[];
}

interface IWizard {
  [key: number]: IWizardByLocation;
}

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  locationId: number | null = null;
  locationName: string | null = null;

  private stepBlock: boolean = true;
  private stepBlockSubject = new BehaviorSubject(this.stepBlock);
  public stepBlock$ = this.stepBlockSubject.asObservable();

  private stepBooking: StepEnum = StepEnum.BOOKING;
  private stepBookingSubject = new BehaviorSubject(this.stepBooking);
  public stepBooking$ = this.stepBookingSubject.asObservable();

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  public setStep(step: StepEnum) {
    this.stepBookingSubject.next(step);
  }

  public setBlock(flag: boolean) {
    this.stepBlockSubject.next(flag);
  }

  public goToNext() {
    let currentStep = this.stepBookingSubject.value;
    let token = this.authService.getAuthToken();

    if (currentStep === StepEnum.BOOKING) {
      this.stepBookingSubject.next(StepEnum.USER_FORM);
      this.router.navigate(['personal-information'], {
        queryParams: { tk: token },
      });
      return false;
    }

    if (currentStep === StepEnum.USER_FORM) {
      if (this.locationId) {
        const wizardSetting: IWizard = environment.WIZARD;
        let stepsHidden = wizardSetting[this.locationId] ? wizardSetting[this.locationId]['HIDE'] : [];

        if (
          stepsHidden && 
          stepsHidden.length > 0 &&
          stepsHidden.includes('ADDON')
        ) {
          this.stepBookingSubject.next(StepEnum.PAYMENT);
          this.goToPayment();

          return true;
        }
      }

      this.stepBookingSubject.next(StepEnum.ADDON);
      this.goToAddons();
      return false;
    }

    if (currentStep === StepEnum.ADDON) {
      this.stepBookingSubject.next(StepEnum.PAYMENT);
      return true;
    }

    return false;
  }

  public goToBooking() {
    let token = this.authService.getAuthToken();
    this.router.navigate(['booking'], {
      queryParams: { tk: token },
    });
  }

  public goToClientForm() {
    let token = this.authService.getAuthToken();
    this.router.navigate(['personal-information'], {
      queryParams: { tk: token },
    });
  }

  public goToAddons() {
    let token = this.authService.getAuthToken();
    this.router.navigate(['addons'], { queryParams: { tk: token } });
  }

  public goToPayment() {
    let token = this.authService.getAuthToken();
    this.router.navigate(['payment'], { queryParams: { tk: token } });
  }

  public goToConfirmation() {
    this.router.navigate(['confirmation']);
  }

  public goToSupport() {
    this.router.navigate(['support']);
  }

  public goToReservationSupport() {
    this.router.navigate(['support-reservation']);
  }
}
