<div class="error-container" *ngIf="display">
    <div class="error-container--line">

    </div>
    <div class="error-container--content-block">
        <span class="error-container--content">
            There was a problem with your payment. Try with another card or choose a different payment method.
        </span>
        <mat-icon (click)="close()"
            class="error-container--close-icon" 
            fontIcon="close">
        </mat-icon>
    </div>
</div>