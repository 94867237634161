import { Injectable } from '@angular/core';
import { DateService } from '../dates/date.service';
import { HttpService } from '../http/http.service';
import { environment } from 'src/environment/environment';
import { AppService } from '../app/app.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ShopcartService } from '../shopcart/shopcart.service';

interface IBookingTravelByLocationId {
  [key: number]: number;
}

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  private inventoryList : Date[] = [];
  private inventorySubject : BehaviorSubject<Date[]> = new BehaviorSubject(this.inventoryList);
  public inventories$ : Observable<Date[]> = this.inventorySubject.asObservable();

  constructor(
    private dateService : DateService,
    private httpService : HttpService,
    private appService : AppService,
  ) { }

  public async loadInventory(
    productId : number,
    locationId : number
  ) : Promise<Date[]>
  {
    try {
      const bookingTravelByLocation: IBookingTravelByLocationId = environment.BOOKING_TRAVEL.LOCATIONS;
      const bookingTravel = bookingTravelByLocation[this.appService.getAppConfig().locationId];

      const startDate = this.dateService.plusDayToDate(bookingTravel, new Date());
      const endDate = this.dateService.getLastDayMonth(startDate);
      const starDateStr = this.dateService.toString(startDate);
      const endDateStr = this.dateService.toString(endDate);

      const response = await this.httpService.getAvaibility({
        productId : productId,
        locationId : locationId,
        dateStart : starDateStr,
        dateEnd : endDateStr,
        promocode : null,
      });

      let inventoryList = response.data.map(item => item.inventoryDate);
      this.inventoryList = [...inventoryList];
      this.inventorySubject.next(this.inventoryList);
      return inventoryList;
    } catch (error) {
      console.log(error)
      throw error;
    }
  }

  public async loadInventoryByRange(
    productId : number,
    startDate : Date,
    endDate : Date
  ){
    try {
      const starDateStr = this.dateService.toString(startDate);
      const endDateStr = this.dateService.toString(endDate);
      const {locationId} = this.appService.getLocation();

      const response = await this.httpService.getAvaibility({
        productId : productId,
        locationId : locationId,
        dateStart : starDateStr,
        dateEnd : endDateStr,
        promocode : null,
      });

      let inventoryList = response.data.map(item => item.inventoryDate);

      this.inventoryList = this.inventoryList.concat([...inventoryList]);
      this.inventorySubject.next(this.inventoryList);
      return inventoryList;
    } catch (error) {
      console.log(error)
      throw error;
    }
  }

  public async getInventoryCombo(comboId : number) : Promise<Date[]>
  {
    try {
      //the productId is the discountId to access the combo in discount endpoint
      const data = await this.httpService.getDiscountById(comboId);

      const dateList = await this.loadInventory(data.productList[0].productId, data.productList[0].locationId)

      this.inventoryList = dateList;

      this.inventorySubject.next(this.inventoryList);

      return this.inventoryList;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }


  public async getInventoryComboByRange(comboId : number, startDate : Date, endDate : Date) : Promise<Date[]>
  {
    try {
      //the productId is the discountId to access the combo in discount endpoint
      const data = await this.httpService.getDiscountById(comboId);
      let inventoryList : Date[] = [];

      // push all dates event are duplicated by product
      for await (let product of data.productList){
        const dateList = await this.loadInventoryByRange(product.productId,startDate,endDate)
        inventoryList.push(...dateList);
      }

      let iventoryFormatted = inventoryList.filter((value, index, array) => array.indexOf(value) !== index);

      this.inventoryList = this.inventoryList.concat([...iventoryFormatted]);

      this.inventorySubject.next(this.inventoryList);

      return inventoryList;

    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
