import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IPayPalConfig } from 'ngx-paypal';
import { AppService } from 'src/app/services/app/app.service';
import { HttpService } from 'src/app/services/http/http.service';
@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.css']
})
export class PaypalComponent {
  @Input() reservationId: string = "";
  @Input() clientId: string = "";
  @Input() bankId: number = 0;
  @Input() amount: number = 0;

  public payPalConfig?: IPayPalConfig;

  constructor(
    private httpService: HttpService,
    private router: Router,
    private appService: AppService,
  ) { }

  async ngOnInit() {
    this.payPalConfig = this.initializePaypal(
      this.reservationId,
      this.appService.getCurrentCurrency(),
      this.clientId,
      this.bankId,
      this.amount
    )
  }

  public initializePaypal(
    reservationId: string,
    currencyCode: string,
    paypalClientId: string,
    bankId: number,
    amount: number
  ): IPayPalConfig {
    console.log('initializePaypal.Parameters ->', reservationId, currencyCode, paypalClientId, bankId, amount);

    let payPalConfig: IPayPalConfig = {
      currency: currencyCode,
      clientId: paypalClientId,
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onInit: (data, actions) => {},
      createOrderOnServer: async (data) =>
        this.createPaypalOrder(reservationId, bankId, amount),
      onApprove: async (data, actions) =>
        this.onApproveOrder(actions, reservationId),
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        // this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        // this.showCancel = true;
      },
      onError: (err) => {
        console.log('OnError', err);
        // this.showError = true;
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        // this.resetStatus();
      },
    };
    console.log('initializePaypal.Return => ', payPalConfig);

    return payPalConfig;
  }

  async createPaypalOrder(reservationId: string, bankId: number, amount: number) {
    const response = await this.httpService.createPaypalOrder(reservationId, bankId, amount);
    return response.transactionId;
  }

  async onApproveOrder(actions: any, reservationId: string) {
    try {
      const paypalResponse = await actions.order.get();
      await this.httpService.capturePaypalOrder(paypalResponse.id, reservationId, this.bankId);
      return this.router.navigate(['/reservation-confirmation', reservationId]);
    } catch (error) {
      return this.router.navigate(['/support-reservation']);
    }
  }

}
