<div class="product-select--container border border--light">
  <form [formGroup]="scheduleForm">
    <mat-select
      class="select"
      formControlName="schedule"
      placeholder="{{ 'HOME.PLACEHOLDER_SELECT_SCHEDULE' | translate }}"
      [class.disabled]="selfSelected"
    >
      <ng-container *ngFor="let schedule of scheduleList; let index = index">
        <mat-option [value]="schedule">
          <span>{{ schedule.date | date : "shortTime" }}</span>
        </mat-option>
      </ng-container>
    </mat-select>
  </form>
</div>
