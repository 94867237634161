import { Component } from '@angular/core';
import { ActivatedRoute, Params, Route, Router } from '@angular/router';
import { DateService } from 'src/app/services/dates/date.service';

@Component({
  selector: 'app-mit-payment',
  templateUrl: './mit-payment.component.html',
  styleUrls: ['./mit-payment.component.css']
})
export class MitPaymentComponent {
  public bankName : string = "";
  public clientEmail : string = "";
  public currencyCode : string = "";
  public total : number = 0;
  public paymentReference : string = "";
  public paymentDate : Date = new Date();
  public cardCompany : string = "";

  public showDeclinedPayment : boolean = false;
  public showSuccessPayment : boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router : Router,
    private dateService : DateService
    ) { }

  ngOnInit(){
    this.route.queryParams
    .subscribe(params => {
      if(params['nbResponse'] == 'Aprobado'){
        this.showSuccessPayment = true;
        this.setSuccessPayment(params);
      }

      if(params['nbResponse'] == 'Rechazado'){
        this.showDeclinedPayment = true;
        this.setDeclinePayment(params);
      }
    });
  }

  private setSuccessPayment(params : Params){
    this.bankName = params['bank'];
      this.clientEmail = params['email'];
      this.currencyCode = params['nbMoneda'];
      this.total = params['importe'];
      this.paymentReference = params['referencia'];
      this.dateService.parseDateMit(params['fecha']);
      this.cardCompany = params['marca'];
  }

  private setDeclinePayment(params : Params){
      this.bankName = params['bank'];
      this.clientEmail = params['email'];
      this.currencyCode = params['nbMoneda'];
      this.total = params['importe'];
      this.paymentReference = params['referencia'];
      this.cardCompany = params['marca'];
  }
  
}
