import { DiscountTypeEnum } from "../Interfaces/Enums/DiscountType.enum";

export enum PaxTypeEnum {
  SINGLE = 0,
  CHILD = 1,
  INFANT = 3,
}

export interface IPax{
    id : PaxTypeEnum;
    generalId? : number; // field to get the original id by discount or combo, that usually have -1
    name : string;
    quantity : number;
    subtotal : number; // price without any discount
    price : number; // individual price
    total : number;
    discount? : number;
    discountTotal? : number;
    discountMultiples? :number;
    paxDiscountType? : DiscountTypeEnum;
    priority : number; // set to validate the dependencia between adults and other paxes
    blocked : boolean; // to set disabled if the main pax is zero or something logic
}
